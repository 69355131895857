import React from "react";
import "../App.css";

import Comparison from "./Comparison";
import SocialProof from "./SocialProof";
// Import at the top of your landing page
import InternalLinksSection from "./InternalLinksSection";

// Use the component where you want to place it in the landing page JSX

import IdeaBox from "./IdeaBox";
import TakeAction from "./TakeAction";

import HeroSection from "./HeroSection";
import Footer from "./Footer";
import ActionSteps from "./ActionSteps";
import CarouselComponent from "./Carousel";
import Working from "./WorkingExplanation";
import AllInOneTool from "./AllInOneTool";
import UseCaseComponent from "./UseCaseComponent";

function LandingPage() {
  return (
    <main className="landing-page">
      {/* first section */}
      <section>
        <HeroSection />
      </section>
      {/* <section style={{ backgroundColor: "#f5f5f7", color: "#000000", padding: "50px 0" }}>
        <IdeaBox />
      </section> */}
      {/* <section className="carousel-section" style={{ backgroundColor: "#ffffff", color: "#000000" }}>
        <CarouselComponent />
      </section>{" "} */}
      {/* Social Proof Section */}
      <section>
        <SocialProof />
      </section>
      <section>
        <Working />
      </section>
      <section>
        <AllInOneTool />
      </section>
      <section>
        <UseCaseComponent />
      </section>
      {/* <section>
        <InternalLinksSection />
      </section>
      <section>
        <Comparison />
      </section> */}
      {/* 
      <section>
        <ActionSteps />
      </section> */}
      {/* <section style={{ color: "#ffffff", backgroundColor: "#102A43" }}>
        <TakeAction />
      </section> */}
      <Footer />
    </main>
  );
}

export default LandingPage;
