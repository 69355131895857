import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";
import "../css/Chatbot.css";
import { useContext } from "react";
import { AuthContext } from "../context/auth.context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import simoneSkills from "../assets/Simone_Skills.png";
import { marked } from "marked"; // Named import for 'marked'
import DOMPurify from "dompurify";

function SimoneSkills() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ttsData, setTtsData] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const { isLoggedIn, user } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Automatically scroll to the latest message
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // Automatically play TTS when data updates
    if (ttsData) {
      const audio = new Audio(`data:audio/mp3;base64,${ttsData}`);
      audio.play().catch((error) => console.error("Error playing audio:", error));
    }

    // Test the backend connection
    axios
      .get(`${API_URL_NEW_BACKEND}/api/test`)
      .then((response) => {
        console.log("Backend connection test:", response.data);
      })
      .catch((error) => {
        console.error("Error connecting to backend:", error);
      });
  }, [messages, ttsData]);

  const handleSend = async () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput === "") {
      console.error("User input is empty");
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { user: "user", text: trimmedInput }]);
    setUserInput("");

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_NEW_BACKEND}/api/skill-building`, { userMessage: trimmedInput });
      console.log("Response from backend:", response.data);
      // Use `marked` to convert Markdown to HTML and `DOMPurify` to sanitize it
      const safeHtml = DOMPurify.sanitize(marked(response.data.response));

      setMessages((prevMessages) => [...prevMessages, { user: "assistant", text: safeHtml, isMarkdown: true }]);
      setTtsData(response.data.ttsData); // Set TTS data
    } catch (error) {
      console.error("Error while fetching data from backend:", error);
      setMessages((prevMessages) => [...prevMessages, { user: "bot", text: "Error: Unable to fetch response." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return (
    <div className="chatbot-container">
      <div className="introduction-container">
        <div className="introduction-text">
          <h2>Boost Specific Case Interview Skills with Simone!</h2>
          <p>
            I'm Simone, your guide for acing strategy consulting interviews. Choose a focus area - case math, framework development, or market sizing
            - and I'll provide customized scenarios and feedback.
          </p>
          <p>
            Have questions on interview strategies or specific problems? Just ask! Whether it's about estimating market sizes or developing effective
            frameworks, I'm here to assist.
          </p>
          <p>Ready to start? Type in something like "Let's practice case math," and we'll begin your tailored interview prep journey!</p>
        </div>
        <div className="introduction-image">
          <img src={simoneSkills} alt="Simone" />
        </div>
      </div>

      <div className="messages-container" aria-live="polite">
        {messages.map((message, index) =>
          message.isMarkdown ? (
            <div key={index} className={`chat-message ${message.user}`} dangerouslySetInnerHTML={{ __html: message.text }} />
          ) : (
            <p key={index} className={`chat-message ${message.user}`}>
              {message.text}
            </p>
          )
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="user-input-container">
        <input
          className="user-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} disabled={loading || userInput.trim() === ""}>
          Send
        </button>
      </div>
    </div>
  );
}

export default SimoneSkills;
