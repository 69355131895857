import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../css/Choose.css";
import NewImg from "../assets/van-tay-media-TFFn3BYLc5s-unsplash.jpg";
import arithmetic from "../assets/arithmetic.jpg";
import LockIcon from "../assets/lock-icon.png";
import UnlockedLockIcon from "../assets/unlock.png";
import Mck_logo from "../assets/Mck_logo.png";
import Bcg_logo from "../assets/Bcg_logo.png";
import Bain_logo from "../assets/Bain_logo.png";

import { Link } from "react-router-dom";
import { API_URL } from "../config/config.index";
import { AuthContext } from "../context/auth.context";

function ChoosePersonalCompany() {
  const { isLoggedIn, user, checkPremiumMembership } = useContext(AuthContext);
  const [userPlan, setUserPlan] = useState(""); // Changed to a single string
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    checkPremiumMembership();

    async function fetchData() {
      if (!isLoggedIn || !user || !user.email) {
        setLoading(false);
        return;
      }

      try {
        const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
        const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

        // Check if stripeCustomerId exists before making the next API call
        if (!stripeCustomerId) {
          console.warn("No Stripe customer ID found for user:", user.email);
          setLoading(false);
          return;
        }

        const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
        console.log("Received product response:", productResponse.data);

        const planName = productResponse.data.productName;
        setUserPlan(planName);
        console.log("User's subscription plan:", planName);
      } catch (error) {
        console.error("Error fetching user plan:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [isLoggedIn, checkPremiumMembership, user]);

  const userHasPlan = (planName) => {
    const planNamesMap = {
      Basic: "Basic Plan",
      Premium: "Premium Plan",
      Pro: "Pro Plan",
    };

    if (planName === "Basic") {
      return userPlan === planNamesMap["Basic"] || userPlan === planNamesMap["Premium"] || userPlan === planNamesMap["Pro"];
    } else if (planName === "Premium") {
      return userPlan === planNamesMap["Premium"] || userPlan === planNamesMap["Pro"];
    } else if (planName === "Pro") {
      return userPlan === planNamesMap["Pro"];
    } else {
      return false;
    }
  };

  const Card = ({ title, image, description, link, buttonText, badge, isLocked }) => {
    const lockImg = isLocked ? LockIcon : UnlockedLockIcon;

    return (
      <div className="choice-card">
        {badge && <span className={`badge ${badge.toLowerCase()}`}>{badge}</span>}

        <h2 className="choice-title">{title}</h2>
        <div className="choice-image-container">
          <img className="choice-image" src={image} alt={title} />
        </div>
        <p className="choice-description">{description}</p>
        <Link to={link} className={`choice-button ${isLocked ? "premium-button" : ""}`}>
          {buttonText}
        </Link>
      </div>
    );
  };

  return (
    <div className="choice-container">
      <Card
        title="McKinsey & Company"
        image={Mck_logo}
        description="Perfect your storytelling for McKinsey & Company's PEI, focusing on personal impact, leadership, and problem-solving experiences. Receive feedback tailored to McKinsey's unique interview approach, enhancing your narrative clarity and impact."
        link="/practice-personal-experience-mckinsey"
        buttonText="Practice"
      />

      <Card
        title="Boston Consulting Group"
        image={Bcg_logo}
        description="Prepare for Boston Consulting Group's in-depth personal case discussions. Engage in scenarios that test your creativity and analytical skills, with feedback aimed at refining your approach to BCG's distinctive interview style."
        link="/practice-personal-experience-bcg"
        buttonText="Practice"
      />

      <Card
        title="Bain & Company"
        image={Bain_logo}
        description="Train for Bain & Company's collaborative interview format. Focus on articulating your teamwork and leadership skills through tailored personal experience scenarios, gaining insights to excel in Bain's interactive interview sessions."
        link="/practice-personal-experience-bain"
        buttonText="Practice"
      />
    </div>
  );
}

export default ChoosePersonalCompany;
