/** @jsxImportSource @emotion/react */
import { useParams } from "react-router-dom";
import blogData from "../assets/BlogData";
import "../css/BlogPostDetail.css"; // Import the CSS file
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

function BlogPostDetail() {
  const { blogSlug } = useParams();
  const post = blogData.find((post) => post.slug === blogSlug);

  console.log(post); // Log the found post

  if (!post) {
    return <p>No blog post found.</p>;
  }

  // rest of your component
  return (
    <div className="blog-post-detail-container">
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.description} />
        {/* Open Graph metadata for social sharing */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.image} />
        {/* Twitter Card metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.description} />
        <meta name="twitter:image" content={post.image} />
      </Helmet>

      {/* Alt tag added for better SEO and accessibility */}
      <img className="blog-post-image" src={post.image} alt={post.title} />

      <h2 className="blog-post-title">{post.title}</h2>
      <p className="blog-post-description">{post.description}</p>
      <ReactMarkdown className="blog-post-content">{post.content}</ReactMarkdown>
    </div>
  );
}

export default BlogPostDetail;
