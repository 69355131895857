import React, { useState, useMemo, useContext } from "react";
import VerbalReasoningQuestion from "./VerbalReasoningQuestion";
import PremiumChatInterface from "./PremiumChatInterface";
import sentenceCorrectionQuestions from "../components/pages/data/sentenceCorrection.json";
import readingComprehensionQuestions from "../components/pages/data/readingComprehension.json";
import criticalReasoningQuestions from "../components/pages/data/criticalReasoning.json";
import "../css/Chatbot.css";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../config/config.index";
import { useNavigate } from "react-router-dom"; // Import to redirect user
import { AuthContext } from "../context/auth.context"; // Import your AuthContext here
import Choose_Quant from "../assets/Choose_quant.png";
import { faL } from "@fortawesome/free-solid-svg-icons";

const VerbalQuestionnaire = () => {
  const [questionType, setQuestionType] = useState("sentenceCorrection"); // default question type
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPracticeMode, setIsPracticeMode] = useState(false);
  const [isPremium, setIsPremium] = useState(true); // Assuming premium status for testing
  const [resetChat, setResetChat] = useState(false);
  const [userPlan, setUserPlan] = useState("Basic Plan"); // Assuming basic plan for testing
  const [showVideoSection, setShowVideoSection] = useState(false);

  const { isLoggedIn, user } = useContext(AuthContext); // Use your AuthContext here
  const navigate = useNavigate(); // Initialize navigate hook

  useEffect(() => {
    async function fetchUserPlan() {
      // Check if user is logged in and has valid user data
      if (!isLoggedIn || !user || !user.email) {
        toast.info("Please log in to access this page.");
        navigate("/login"); // Redirect to login if not logged in or user data is not available
        return;
      }
    }

    fetchUserPlan();
  }, [isLoggedIn, user, navigate]);

  // useEffect(() => {
  //   async function fetchUserPlan() {
  //     // Check if user is logged in and has valid user data
  //     if (!isLoggedIn || !user || !user.email) {
  //       toast.info("Please get the Basic Plan to access this page.");
  //       navigate("/plans"); // Redirect to login if not logged in or user data is not available
  //       return;
  //     }

  //     try {
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       if (stripeCustomerId) {
  //         const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //         setUserPlan(productResponse.data.productName);
  //         setIsPremium(productResponse.data.productName === "Premium Plan");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   }

  //   fetchUserPlan();
  // }, [isLoggedIn, user, navigate]);

  useEffect(() => {
    // After fetching the user plan, check if they have the required plan
    if (userPlan !== "Basic Plan" && userPlan !== "Premium Plan") {
      toast.info("Access to these questions requires a Basic or Premium Plan.");
      navigate("/plans"); // Redirect to the plans page if the user doesn't have the required plan
    }
  }, [userPlan, navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        // Adjust this value as needed
        setShowVideoSection(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const questionsMap = {
    sentenceCorrection: sentenceCorrectionQuestions.questions,
    readingComprehension: readingComprehensionQuestions.questions,
    criticalReasoning: criticalReasoningQuestions.questions,
  };

  console.log("Questions Map: ", questionsMap); // Log the questionsMap to see if it's structured correctly

  const shuffledQuestions = useMemo(() => {
    return questionsMap[questionType].sort(() => Math.random() - 0.5);
  }, [questionType]);

  const typeButtons = useMemo(() => {
    console.log("Generating type buttons for: ", Object.keys(questionsMap));
    return Object.keys(questionsMap).map((type) => {
      const typeName = type.replace(/([A-Z])/g, " $1").trim(); // Converts "QuestionType" to "Question Type"
      const isActive = questionType === type;
      console.log(`Type: ${type}, Active: ${isActive}`);
      return (
        <button key={type} onClick={() => handleQuestionTypeChange(type)} className={`type-button ${isActive ? "active" : ""}`}>
          <span className={`icon ${isActive ? "icon-active" : ""}`}></span>
          {typeName}
          {isActive && <div className="underline"></div>}
        </button>
      );
    });
  }, [questionType, questionsMap]);

  // Additional log for the rendered buttons
  console.log("Rendered type buttons: ", typeButtons);

  const currentQuestion = shuffledQuestions[currentQuestionIndex] || {};

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((currentIndex) => currentIndex + 1);
      setResetChat(true);
    } else {
      alert("You've completed all questions in this category!");
      setCurrentQuestionIndex(0); // Optionally reset to the first question
    }
  };

  const togglePracticeMode = () => {
    setIsPracticeMode(!isPracticeMode);
  };

  const onResetDone = () => {
    setResetChat(false);
  };

  const handleQuestionTypeChange = (type) => {
    setQuestionType(type);
    setCurrentQuestionIndex(0); // Reset to first question of the new type
  };
  const scrollToVideoSection = () => {
    setShowVideoSection(true);
  };

  return (
    <div>
      <Helmet>
        <title>GMAT Verbal Practice - Interactive Questionnaire</title>
        <meta
          name="description"
          content="Enhance your GMAT verbal skills with our interactive questionnaire. Tackle sentence correction, critical reasoning, and reading comprehension questions and prepare for success."
        />
        <meta
          name="keywords"
          content="GMAT Verbal Practice, GMAT Sentence Correction, GMAT Reading Comprehension, GMAT Critical Reasoning, GMAT Preparation"
        />
        {/* Additional relevant meta tags */}
      </Helmet>
      <div className="type-buttons-container">{typeButtons}</div>

      <div className="practice-mode-toggle">
        <label htmlFor="practice-mode-switch" className="switch-label">
          Practice Mode:
        </label>
        <label className="practice-mode-switch">
          <input id="practice-mode-switch" type="checkbox" checked={isPracticeMode} onChange={togglePracticeMode} />
          <span className="slider round"></span>
        </label>
      </div>

      <VerbalReasoningQuestion
        questionData={currentQuestion}
        onNextQuestion={handleNextQuestion}
        isPracticeMode={isPracticeMode}
        isPremium={isPremium}
      />

      {isPremium && (
        <PremiumChatInterface
          userId={""}
          isPremiumUser={isPremium}
          currentQuestion={currentQuestion}
          resetChat={resetChat}
          onResetDone={onResetDone}
          subjectArea="english"
        />
      )}
    </div>
  );
};

export default VerbalQuestionnaire;
