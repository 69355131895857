import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../config/config.index.js";
import { AuthContext } from "../context/auth.context";
import { toast } from "react-toastify";

const FreeCaseStatusManager = ({ children, requiredPlan, caseType, onUpdateStatus }) => {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoggedIn || !user || !user.email) {
      toast.error("You must be logged in to access this page");
      navigate("/login");
      return;
    }

    const fetchUserPlan = async () => {
      try {
        const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
        const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

        if (!stripeCustomerId) {
          checkUserFreeCaseStatus();
          return;
        }

        const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
        const planName = productResponse.data.productName;

        if (planName === requiredPlan || planName === "Premium Plan") {
          // User has the required subscription, no need to check free case status
        } else {
          checkUserFreeCaseStatus();
        }
      } catch (error) {
        console.error("Error fetching user plan:", error);
        navigate("/error");
      }
    };

    fetchUserPlan();
  }, [isLoggedIn, user, navigate, requiredPlan]);

  const checkUserFreeCaseStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/checkFreeCaseStatus`, {
        params: { email: user.email },
      });

      const { hasCreatedFreeCarlCase, hasCreatedFreeSimoneCase, hasCreatedFreePersonalExperience } = response.data;

      let hasCreatedFreeCase = false;
      switch (caseType) {
        case "Carl":
          hasCreatedFreeCase = hasCreatedFreeCarlCase;
          break;
        case "Simone":
          hasCreatedFreeCase = hasCreatedFreeSimoneCase;
          break;
        case "PersonalExperience":
          hasCreatedFreeCase = hasCreatedFreePersonalExperience;
          break;
        default:
          toast.error("Invalid case type");
          navigate("/error");
          return;
      }

      if (hasCreatedFreeCase) {
        toast.info("You have already used your free case. Check out our plans for more!");
        navigate("/plans");
      } else {
        const timer = setTimeout(() => updateFreeCaseStatus(caseType), 1000); // For testing; replace with 1800000 for production
        return () => clearTimeout(timer);
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  };

  const updateFreeCaseStatus = async (caseType) => {
    try {
      let updateField = "";
      switch (caseType) {
        case "Carl":
          updateField = "hasCreatedFreeCarlCase";
          break;
        case "Simone":
          updateField = "hasCreatedFreeSimoneCase";
          break;
        case "PersonalExperience":
          updateField = "hasCreatedFreePersonalExperience";
          break;
        default:
          throw new Error(`Invalid case type: ${caseType}`);
      }

      const response = await axios.post(`${API_URL}/api/user/updateFreeCaseStatus`, {
        email: user.email,
        [updateField]: true,
      });

      console.log(`Update response for ${caseType}:`, response);
    } catch (error) {
      console.error("Error updating user's free case status:", error);
    }
  };

  return React.Children.map(children, (child) => React.cloneElement(child, { updateFreeCaseStatus: () => updateFreeCaseStatus(caseType) }));
};

export default FreeCaseStatusManager;
