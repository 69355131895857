import React from "react";
import "../css/PremiumHint.css";

const PremiumHint = ({ isPremium, isPracticeMode }) => {
  if (!isPremium && isPracticeMode) {
    return <div className="subtle-premium-prompt">"Unlock detailed explanations with a Premium subscription."</div>;
  }
  return null;
};
export default PremiumHint;
