/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const blogPostStyle = css`
  margin-bottom: 30px;
  padding: 20px;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  max-width: 400px; /* Adjust the max-width to control the size */
  margin-right: 20px; /* Add some spacing between blog posts */

  &:hover {
    transform: translateY(-10px);
  }
`;

const blogImageStyle = css`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
`;

const blogTitleStyle = css`
  font-size: 20px; /* Reduce the font size for a smaller appearance */
  color: #1d1d1b;
  margin-top: 10px; /* Decrease the top margin for closer alignment */
  text-decoration: none;
`;

const blogDescriptionStyle = css`
  font-size: 14px; /* Reduce the font size for a smaller appearance */
  color: #777;
  margin-top: 5px; /* Decrease the top margin for closer alignment */
`;

const blogTopicsStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px; /* Decrease the top margin for closer alignment */
`;

const topicButtonStyle = css`
  padding: 5px 10px;
  margin: 5px;
  border-radius: 25px;
  border: 1px solid #ccc;
  background-color: transparent;
  color: #1d1d1b;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #f2f2f2;
    color: #1d1d1b;
  }
`;

function BlogPost({ post }) {
  return (
    <div css={blogPostStyle}>
      <img css={blogImageStyle} src={post.image} alt={post.title} />
      <Link to={`/blog/${post.slug}`} css={blogTitleStyle}>
        <h3>{post.title}</h3> {/* Use h3 for a smaller heading */}
      </Link>
      <p css={blogDescriptionStyle}>{post.description}</p>
      <div css={blogTopicsStyle}>
        {post.topics.map((topic) => (
          <button key={topic} css={topicButtonStyle}>
            {topic}
          </button>
        ))}
      </div>
      <Link to={`/blog/${post.id}`}>Read More</Link>
    </div>
  );
}

export default BlogPost;
