import React, { useState, useRef, useEffect } from "react";
import "../css/QuantitativeQuestion.css";
import { toast } from "react-toastify";
import PremiumHint from "./PremiumHint";
import Choose_Quant from "../assets/Choose_quant.png";
import Choose_verbal from "../assets/Choose_verbal.png";
import Video_section_similar_question from "../assets/Video_section_similar_question.png";
import { useNavigate } from "react-router-dom";

const QuantitativeQuestion = ({ questionData, onNextQuestion, isPracticeMode }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [isPremium, setIsPremium] = useState(true); // Replace with actual logic
  const [showButton, setShowButton] = useState(true); // Replace with actual logic
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  const [videoSrc, setVideoSrc] = useState(null);
  const [imageSrc, setImageSrc] = useState(Choose_Quant);
  const [imageSrc2, setImageSrc2] = useState(Choose_verbal);
  const [videoSrc2, setVideoSrc2] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const videosSectionRef = useRef(null);

  // Check if options are available in the question data
  const optionsAvailable = questionData && questionData.options;

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setIsOptionSelected(true);
    if (isPracticeMode) {
      setShowExplanation(false);
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setShowButton(false);
    } else {
      // Scrolling up
      setShowButton(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isOptionSelected) {
      alert("Please select an option before checking the answer.");
      return;
    }
    setShowExplanation(true);
  };

  const handleNext = () => {
    if (!isOptionSelected) {
      alert("Please select an option before continuing.");
      return;
    }
    onNextQuestion();

    setSelectedOption("");
    setIsOptionSelected(false);
    setShowExplanation(false);
  };

  const scrollToVideos = () => {
    videosSectionRef.current.scrollIntoView({ behavior: "smooth" });
    setShowButton(false);
  };

  const handleMouseEnter = () => {
    setVideoSrc("https://www.youtube.com/embed/2uwYHTJp-tk?modestbranding=1&showinfo=0&rel=0&autoplay=1");
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setVideoSrc(null);
    setIsHovering(false);
  };

  const handleMouseEnter2 = () => {
    setVideoSrc2("https://www.youtube.com/embed/_HZRLsKjCj0?modestbranding=1&showinfo=0&rel=0&autoplay=1");
    setIsHovering(true);
  };

  const handleMouseLeave2 = () => {
    setVideoSrc2(null);
    setIsHovering(false);
  };

  return (
    <>
      <div className="question-container">
        <h2>Quantitative Reasoning</h2>

        <form onSubmit={handleSubmit}>
          {questionData.content && questionData.content.image && (
            <div className="question-image">
              <img src={questionData.content.image} alt="Quantitative Reasoning Visual Aid" />
            </div>
          )}
          <div className="question-text">
            {questionData.content &&
              questionData.content.text.split("\n").map((item, key) => {
                return <p key={key}>{item}</p>;
              })}
          </div>{" "}
          <div className="options">
            {optionsAvailable &&
              questionData.options.map((option, index) => (
                <label
                  key={index}
                  className={`option-label ${
                    showExplanation && selectedOption === option.label && questionData.correctAnswer === option.label
                      ? "correct"
                      : showExplanation && selectedOption === option.label
                      ? "incorrect"
                      : showExplanation && questionData.correctAnswer === option.label
                      ? "correct"
                      : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="option"
                    value={option.label}
                    checked={selectedOption === option.label}
                    onChange={handleOptionChange}
                    disabled={showExplanation} // Optionally disable changing the option after checking the answer
                  />
                  {option.text}
                </label>
              ))}
          </div>
          {showExplanation && isPracticeMode && (
            <div className="explanation">
              <span>Explanation:</span> <p>{questionData.explanation}</p>
              <PremiumHint isPremium={isPremium} isPracticeMode={isPracticeMode} />
            </div>
          )}
          <div className="buttons">
            <button type="submit" className="submit-btn">
              {isPracticeMode ? "Check Answer" : "Submit Answer"}
            </button>
            <button type="button" className="next-btn" onClick={handleNext}>
              Next Question
            </button>
          </div>
        </form>
      </div>

      {!isPremium && showButton && (
        <div className="premium-hint">
          <button onClick={scrollToVideos}>Learn About Premium Features &#x2193;</button>
        </div>
      )}

      {!isPremium && (
        <div className="videos-section" ref={videosSectionRef}>
          {videoSrc ? (
            <div className="video-container" onMouseLeave={handleMouseLeave}>
              <iframe src={videoSrc} width="640" height="480" allow="autoplay"></iframe>
              {!isHovering && <div className="video-overlay"></div>}
            </div>
          ) : (
            <img src={imageSrc} alt="Image 1" onMouseEnter={handleMouseEnter} />
          )}
          {videoSrc2 ? (
            <div className="video-container" onMouseLeave={handleMouseLeave2}>
              <iframe src={videoSrc2} width="640" height="480" allow="autoplay"></iframe>
              {!isHovering && <div className="video-overlay"></div>}
            </div>
          ) : (
            <img src={imageSrc2} alt="Image 2" onMouseEnter={handleMouseEnter2} />
          )}
        </div>
      )}
    </>
  );
};

export default QuantitativeQuestion;
