import React, { useState } from "react";
import axios from "axios";
import { API_URL_NEW_BACKEND } from "../config/config.index";

const QuestionGenerator = () => {
  // State to hold the generated question
  const [generatedQuestion, setGeneratedQuestion] = useState(null);

  // Function to call the API and generate a question
  const generateQuestion = async (questionType) => {
    try {
      const response = await axios.post(`${API_URL_NEW_BACKEND}/questiongeneration/generate-question`, { questionType });
      setGeneratedQuestion(response.data); // Update state with the generated question
      console.log("Generated Question:", response.data);
    } catch (error) {
      console.error("Error generating question:", error);
    }
  };

  const generateQuestionSentenceCorrection = async (questionType) => {
    try {
      const response = await axios.post(`${API_URL_NEW_BACKEND}/questiongeneration/generate-sentence-correction-question`, { questionType });
      setGeneratedQuestion(response.data); // Update state with the generated question
      console.log("Generated Question:", response.data);
    } catch (error) {
      console.error("Error generating question:", error);
    }
  };

  const generateQuestionReadingComprehension = async (questionType) => {
    try {
      const response = await axios.post(`${API_URL_NEW_BACKEND}/questiongeneration/generate-reading-comprehension-question`, { questionType });
      setGeneratedQuestion(response.data); // Update state with the generated question
      console.log("Generated Question:", response.data);
    } catch (error) {
      console.error("Error generating question:", error);
    }
  };

  const generateQuestionDataSufficiency = async (questionType) => {
    try {
      const response = await axios.post(`${API_URL_NEW_BACKEND}/questiongeneration/generate-data-sufficiency-question`, { questionType });
      setGeneratedQuestion(response.data); // Update state with the generated question
      console.log("Generated Question:", response.data);
    } catch (error) {
      console.error("Error generating question:", error);
    }
  };

  const generateQuestionCriticalReasoning = async (questionType) => {
    try {
      const response = await axios.post(`${API_URL_NEW_BACKEND}/questiongeneration/generate-critical-reasoning-question`, { questionType });
      setGeneratedQuestion(response.data); // Update state with the generated question
      console.log("Generated Question:", response.data);
    } catch (error) {
      console.error("Error generating question:", error);
    }
  };

  const test = () => {
    try {
      const response = axios.get(`${API_URL_NEW_BACKEND}/questiongeneration/test`);
      console.log(response);
    } catch (error) {
      console.error("Error testing:", error);
    }
  };

  return (
    <div>
      <h1>GMAT Question Generator</h1>
      <h2> Version 0.4</h2>
      {/* Buttons for each question type */}
      <button onClick={() => generateQuestion("Algebra")}>Generate Algebra Question</button>
      <button onClick={() => generateQuestion("Arithmetic")}>Generate Arithmetic Question</button>
      <button onClick={() => generateQuestionCriticalReasoning("Critical Reasoning")}>Generate Critical Reasoning Question</button>
      <button onClick={() => generateQuestionDataSufficiency("Data Sufficiency")}>Generate Data Sufficiency Question</button>
      <button onClick={() => generateQuestionSentenceCorrection("Sentence Correction")}>Generate Sentence Correction Question</button>
      <button onClick={() => generateQuestionReadingComprehension("Reading Comprehension")}>Generate Reading Comprehension Question</button>
      <button onClick={test}>Test</button>
      {/* Display the generated question */}
      {generatedQuestion && (
        <div>
          <h2>Generated Question</h2>
          <h3>Question Type: {generatedQuestion.questionType}</h3>
          <p>{generatedQuestion.content.text}</p> {/* Adjust based on your Question schema */}
          {/* Render options, correct answer, etc. */}
        </div>
      )}
    </div>
  );
};

export default QuestionGenerator;
