import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";
import "../css/Chatbot.css";
import { marked } from "marked"; // Named import for 'marked'
import DOMPurify from "dompurify";
import { useContext } from "react";
import Mck_logo from "../assets/Mck_logo.png";
import { AuthContext } from "../context/auth.context.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PersonalExperienceMCK({ updateFreeCaseStatus }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ttsData, setTtsData] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const { isLoggedIn, user } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  //     try {
  //       setLoading(true);

  //       // Fetch Stripe Customer ID
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       if (!stripeCustomerId) {
  //         console.warn("No Stripe customer ID found for user:", user.email);
  //         toast.error("You need a Basic Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       // Fetch Subscription Details
  //       const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //       const planName = productResponse.data.productName;
  //       if (planName !== "Premium Plan") {
  //         toast.error("You need a Premium Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       setUserPlan(planName);
  //     } catch (error) {
  //       console.error("Error fetching user plan:", error);
  //       navigate("/error");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchUserPlan();
  // }, [isLoggedIn, user, navigate]);

  useEffect(() => {
    // Automatically scroll to the latest message
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // Automatically play TTS when data updates
    if (ttsData) {
      const audio = new Audio(`data:audio/mp3;base64,${ttsData}`);
      audio.play().catch((error) => console.error("Error playing audio:", error));
    }

    // Test the backend connection
    axios
      .get(`${API_URL_NEW_BACKEND}/api/test`)
      .then((response) => {
        console.log("Backend connection test:", response.data);
      })
      .catch((error) => {
        console.error("Error connecting to backend:", error);
      });
  }, [messages, ttsData]);

  const handleSend = async () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput === "") {
      console.error("User input is empty");
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { user: "user", text: trimmedInput }]);
    setUserInput("");

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_NEW_BACKEND}/api/personal-experience-mckinsey`, { userMessage: trimmedInput });
      console.log("Response from backend:", response.data);

      const safeHtml = DOMPurify.sanitize(marked(response.data.response));
      setMessages((prevMessages) => [...prevMessages, { user: "assistant", text: safeHtml, isMarkdown: true }]);
      setTtsData(response.data.ttsData);

      // Here, check if the interaction was successful. This could be based on the response data.
      // For instance, if response.data indicates a successful interaction:
      if (response.data && response.data.successfulInteraction) {
        // Adjust this condition as per your backend logic
        await updateFreeCaseStatus(); // This should trigger the update in free case status
        console.log("Free case status updated successfully.");
      }
    } catch (error) {
      console.error("Error while fetching data from backend:", error);
      setMessages((prevMessages) => [...prevMessages, { user: "bot", text: "Error: Unable to fetch response." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  // practice-personal-experience-mckinsey
  return (
    <div className="pei-chatbot-container">
      <section className="pei-intro-container" aria-labelledby="pei-heading">
        <header>
          <h2 id="pei-heading">Master Your Personal Experience Interview for McKinsey & Company!</h2>
          <p>
            Welcome to your specialized PEI prep assistant. I'm here to guide you through the nuances of McKinsey & Company's Personal Experience
            Interviews. Choose a focus area - <strong>inclusive leadership, entrepreneurial drive, personal impact, or courageous change</strong> -
            and I'll provide realistic scenarios and detailed feedback.
          </p>
          <br></br>
          <p>
            Curious about what makes a PEI at McKinsey unique, or need advice on how to structure your stories? Feel free to ask! Whether it's
            crafting impactful narratives or demonstrating your problem-solving skills, I'm here to help you excel.
          </p>
          <br></br>
          <p>
            Ready to enhance your interview skills? Just say "Let's start with personal impact," or choose your preferred focus area, and let's dive
            into your personalized PEI practice!
          </p>
        </header>
        <div className="pei-intro-image">
          <img src={Mck_logo} alt="McKinsey & Company logo" />
        </div>
      </section>
      <div className="messages-container" aria-live="polite">
        {messages.map((message, index) =>
          message.isMarkdown ? (
            <div key={index} className={`chat-message ${message.user}`} dangerouslySetInnerHTML={{ __html: message.text }} />
          ) : (
            <p key={index} className={`chat-message ${message.user}`}>
              {message.text}
            </p>
          )
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="user-input-container">
        <label htmlFor="user-input" className="visually-hidden">
          Type your message
        </label>
        <input
          id="user-input"
          className="user-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Type your message..."
          aria-describedby="input-help"
        />
        <button onClick={handleSend} disabled={loading || userInput.trim() === ""} aria-label="Send message">
          Send
        </button>
        <span id="input-help" className="visually-hidden">
          Press Enter to send your message
        </span>
      </div>
    </div>
  );
}

export default PersonalExperienceMCK;
