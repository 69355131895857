import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";
import "../css/Chatbot.css";
import { useContext } from "react";
import { marked } from "marked"; // Named import for 'marked'
import DOMPurify from "dompurify";
import Bain_logo from "../assets/Bain_logo.png";
import { AuthContext } from "../context/auth.context.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PersonalExperienceBain() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ttsData, setTtsData] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const { isLoggedIn, user } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  //     try {
  //       setLoading(true);

  //       // Fetch Stripe Customer ID
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       if (!stripeCustomerId) {
  //         console.warn("No Stripe customer ID found for user:", user.email);
  //         toast.error("You need a Basic Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       // Fetch Subscription Details
  //       const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //       const planName = productResponse.data.productName;
  //       if (planName !== "Premium Plan") {
  //         toast.error("You need a Premium Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       setUserPlan(planName);
  //     } catch (error) {
  //       console.error("Error fetching user plan:", error);
  //       navigate("/error");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchUserPlan();
  // }, [isLoggedIn, user, navigate]);

  useEffect(() => {
    // Automatically scroll to the latest message
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // Automatically play TTS when data updates
    if (ttsData) {
      const audio = new Audio(`data:audio/mp3;base64,${ttsData}`);
      audio.play().catch((error) => console.error("Error playing audio:", error));
    }

    // Test the backend connection
    axios
      .get(`${API_URL_NEW_BACKEND}/api/test`)
      .then((response) => {
        console.log("Backend connection test:", response.data);
      })
      .catch((error) => {
        console.error("Error connecting to backend:", error);
      });
  }, [messages, ttsData]);

  const handleSend = async () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput === "") {
      console.error("User input is empty");
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { user: "user", text: trimmedInput }]);
    setUserInput("");

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_NEW_BACKEND}/api/personal-experience-bain`, { userMessage: trimmedInput });
      console.log("Response from backend:", response.data);
      const safeHtml = DOMPurify.sanitize(marked(response.data.response));

      setMessages((prevMessages) => [...prevMessages, { user: "assistant", text: safeHtml, isMarkdown: true }]);
      setTtsData(response.data.ttsData); // Set TTS data
    } catch (error) {
      console.error("Error while fetching data from backend:", error);
      setMessages((prevMessages) => [...prevMessages, { user: "bot", text: "Error: Unable to fetch response." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return (
    <div className="pei-chatbot-container">
      <div className="pei-intro-container">
        <div className="pei-intro-text">
          <h2>Excel in Your Interview with Bain & Company!</h2>
          <p>
            Welcome to your custom interview preparation assistant for Bain & Company. Here, you can practice and sharpen the skills crucial for
            success in Bain's interview process. Choose from areas like problem-solving abilities, or leadership skills, and I'll guide you through
            realistic scenarios and provide feedback.
          </p>
          <br></br>
          <p>
            Wondering what makes a candidate stand out in a Bain interview, or need tips on effectively communicating your ideas? Don't hesitate to
            ask! From showcasing your analytical prowess to demonstrating your entrepreneurial spirit, I'm here to help you highlight your strengths.
          </p>
          <br></br>
          <p>
            Eager to elevate your interview skills? Just say "Let's start," or choose another focus area, and we'll begin your personalized interview
            practice journey.
          </p>
        </div>
        <div className="pei-intro-image">
          <img src={Bain_logo} alt="Bain & Company" /> {/* Replace with Bain logo */}
        </div>
      </div>
      <div className="messages-container" aria-live="polite">
        {messages.map((message, index) =>
          message.isMarkdown ? (
            <div key={index} className={`chat-message ${message.user}`} dangerouslySetInnerHTML={{ __html: message.text }} />
          ) : (
            <p key={index} className={`chat-message ${message.user}`}>
              {message.text}
            </p>
          )
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="user-input-container">
        <input
          className="user-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} disabled={loading || userInput.trim() === ""}>
          Send
        </button>
      </div>
    </div>
  );
}

export default PersonalExperienceBain;
