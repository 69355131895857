import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";
import "../css/Chatbot.css";
import { useContext } from "react";
import { marked } from "marked"; // Named import for 'marked'
import DOMPurify from "dompurify";
import Bcg_logo from "../assets/Bcg_logo.png";
import { AuthContext } from "../context/auth.context.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PersonalExperienceBCG() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ttsData, setTtsData] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const { isLoggedIn, user } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  //     try {
  //       setLoading(true);

  //       // Fetch Stripe Customer ID
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       if (!stripeCustomerId) {
  //         console.warn("No Stripe customer ID found for user:", user.email);
  //         toast.error("You need a Basic Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       // Fetch Subscription Details
  //       const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //       const planName = productResponse.data.productName;
  //       if (planName !== "Premium Plan") {
  //         toast.error("You need a Premium Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       setUserPlan(planName);
  //     } catch (error) {
  //       console.error("Error fetching user plan:", error);
  //       navigate("/error");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchUserPlan();
  // }, [isLoggedIn, user, navigate]);

  useEffect(() => {
    // Automatically scroll to the latest message
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    // Automatically play TTS when data updates
    if (ttsData) {
      const audio = new Audio(`data:audio/mp3;base64,${ttsData}`);
      audio.play().catch((error) => console.error("Error playing audio:", error));
    }

    // Test the backend connection
    axios
      .get(`${API_URL_NEW_BACKEND}/api/test`)
      .then((response) => {
        console.log("Backend connection test:", response.data);
      })
      .catch((error) => {
        console.error("Error connecting to backend:", error);
      });
  }, [messages, ttsData]);

  const handleSend = async () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput === "") {
      console.error("User input is empty");
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { user: "user", text: trimmedInput }]);
    setUserInput("");

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_NEW_BACKEND}/api/personal-experience-bcg`, { userMessage: trimmedInput });
      console.log("Response from backend:", response.data);

      const safeHtml = DOMPurify.sanitize(marked(response.data.response));

      setMessages((prevMessages) => [...prevMessages, { user: "assistant", text: safeHtml, isMarkdown: true }]);
      setTtsData(response.data.ttsData); // Set TTS data
    } catch (error) {
      console.error("Error while fetching data from backend:", error);
      setMessages((prevMessages) => [...prevMessages, { user: "bot", text: "Error: Unable to fetch response." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return (
    <div className="pei-chatbot-container">
      <div className="pei-intro-container">
        <div className="pei-intro-text">
          <h2>Excel in Your Interview with Boston Consulting Group!</h2>
          <p>
            Welcome to your tailored interview preparation assistant for BCG. Here, you can practice and enhance the skills necessary for success in
            BCG's interview process. Choose from areas such as analytical thinking, or leadership skills, and I'll guide you through realistic
            scenarios and provide feedback.
          </p>
          <br></br>
          <p>
            Curious about what it takes to stand out in a BCG interview, or seeking advice on how to effectively communicate your thoughts? Feel free
            to ask! From developing strategic insights to demonstrating your collaborative spirit, I'm here to assist you in showcasing your best
            self.
          </p>
          <br></br>
          <p>
            Ready to take your interview skills to the next level? Just say "Let's start," or choose another area you'd like to focus on, and let's
            begin your tailored interview practice.
          </p>
        </div>
        <div className="pei-intro-image">
          <img src={Bcg_logo} alt="Boston Consulting Group" /> {/* Replace with BCG's logo */}
        </div>
      </div>
      <div className="messages-container" aria-live="polite">
        {messages.map((message, index) =>
          message.isMarkdown ? (
            <div key={index} className={`chat-message ${message.user}`} dangerouslySetInnerHTML={{ __html: message.text }} />
          ) : (
            <p key={index} className={`chat-message ${message.user}`}>
              {message.text}
            </p>
          )
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="user-input-container">
        <input
          className="user-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} disabled={loading || userInput.trim() === ""}>
          Send
        </button>
      </div>
    </div>
  );
}

export default PersonalExperienceBCG;
