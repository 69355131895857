import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/HomePageExam.css";

const HomePageExam = () => {
  const navigate = useNavigate();

  const examOptions = [
    { key: "full", displayName: "Full GMAT Exam", description: "Complete GMAT simulation including all question types." },
    { key: "mini", displayName: "Mini GMAT Exam (not available yet)", description: "A shorter version of the GMAT simulation for quicker practice." },
  ];

  const startExam = (examKey) => {
    // Navigate to the exam route with the selected exam type
    navigate(`/gmat-exam/${examKey}`);
  };

  return (
    <div className="homepage-exam">
      <h1>Select Your GMAT Exam Simulation</h1>
      <div className="exam-options">
        {examOptions.map((option) => (
          <div key={option.key} className="exam-card" onClick={() => startExam(option.key)}>
            <h2>{option.displayName}</h2>
            <p>{option.description}</p>
          </div>
        ))}
      </div>
      <p
        style={{
          textAlign: "center",
          marginTop: "20px",
          fontSize: "18px",
        }}
      >
        Welcome to our GMAT simulation, which is still work in progress. We're actively enhancing features like a comprehensive scoring system to
        track and elevate your progress. As we build and refine, your journey and insights are crucial. Share feedback or queries at{" "}
        <a href="mailto:question@casestudygenerator.com">question@casestudygenerator.com</a>. Let's navigate your GMAT preparation path to success
        together!
      </p>
    </div>
  );
};

export default HomePageExam;
