import React, { useState, useRef, useEffect } from "react";
import "../css/QuantitativeQuestion.css";
import { toast } from "react-toastify";
import PremiumHint from "./PremiumHint";
import Choose_Quant from "../assets/Choose_quant.png";
import Choose_verbal from "../assets/Choose_verbal.png";
import Video_section_similar_question from "../assets/Video_section_similar_question.png";

const VerbalReasoningQuestion = ({ questionData, onNextQuestion, isPracticeMode, isPremium }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [showVideo, setShowVideo] = useState(false);
  const [imageSrc, setImageSrc] = useState(Choose_Quant);
  const [imageSrc2, setImageSrc2] = useState(Choose_verbal);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoSrc2, setVideoSrc2] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  const videosSectionRef = useRef(null);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setShowButton(false);
    } else {
      // Scrolling up
      setShowButton(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setIsOptionSelected(true);
    if (isPracticeMode) {
      setShowExplanation(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isOptionSelected) {
      toast.info("Please select an option before checking the answer.");
      return;
    }
    handleCheckAnswer();
  };

  const handleCheckAnswer = () => {
    setShowExplanation(true);
  };

  const handleNext = () => {
    if (!isOptionSelected) {
      toast.info("Please select an option before continuing.");
      return;
    }
    onNextQuestion();

    setSelectedOption("");
    setIsOptionSelected(false);
    setShowExplanation(false);
  };

  const scrollToVideos = () => {
    videosSectionRef.current.scrollIntoView({ behavior: "smooth" });
    setShowButton(false);
  };

  const handleMouseEnter = () => {
    setVideoSrc("https://www.youtube.com/embed/2uwYHTJp-tk?modestbranding=1&showinfo=0&rel=0&autoplay=1");
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setVideoSrc(null);
    setIsHovering(false);
  };

  const handleMouseEnter2 = () => {
    setVideoSrc2("https://www.youtube.com/embed/_HZRLsKjCj0?modestbranding=1&showinfo=0&rel=0&autoplay=1");
    setIsHovering(true);
  };

  const handleMouseLeave2 = () => {
    setVideoSrc2(null);
    setIsHovering(false);
  };

  // const renderSubtlePremiumPrompt = () => {
  //   if (!isPremium) {
  //     return <div className="subtle-premium-prompt">Did you know? With Premium, you can ask any question you have about this topic.</div>;
  //   }
  // };

  const areOptionsAvailable = questionData && questionData.options;

  return (
    <>
      <div className="question-container">
        <h2>Verbal Reasoning - {questionData.questionType}</h2>
        <form onSubmit={handleSubmit}>
          {questionData.passage && <div className="passage">{questionData.passage}</div>}
          <div className="question-text">
            <p>{questionData.question}</p>
          </div>
          <div className="options">
            {areOptionsAvailable &&
              Object.entries(questionData.options).map(([key, option], index) => (
                <label key={index} className={showExplanation && questionData.correctAnswer === key ? "option-label correct" : "option-label"}>
                  <input type="radio" name="option" value={key} checked={selectedOption === key} onChange={handleOptionChange} />
                  {option}
                </label>
              ))}
          </div>
          {showExplanation && isPracticeMode && (
            <div className="explanation">
              <span>Explanation:</span> <p>{questionData.explanation}</p>
              <PremiumHint isPremium={isPremium} isPracticeMode={isPracticeMode} />
            </div>
          )}
          <div className="buttons">
            <button type="submit" className="submit-btn">
              {isPracticeMode ? "Check Answer" : "Submit Answer"}
            </button>
            <button type="button" className="next-btn" onClick={handleNext}>
              Next Question
            </button>
          </div>
        </form>
        {/* {renderSubtlePremiumPrompt()} */}
      </div>

      {!isPremium && showButton && (
        <div className="premium-hint">
          <button onClick={scrollToVideos}>Learn About Premium Features &#x2193;</button>
        </div>
      )}

      {!isPremium && (
        <div className="videos-section" ref={videosSectionRef}>
          {videoSrc ? (
            <div className="video-container" onMouseLeave={handleMouseLeave}>
              <iframe src={videoSrc} width="640" height="480" allow="autoplay"></iframe>
              {!isHovering && <div className="video-overlay"></div>}
            </div>
          ) : (
            <img src={imageSrc} alt="Image 1" onMouseEnter={handleMouseEnter} />
          )}
          {videoSrc2 ? (
            <div className="video-container" onMouseLeave={handleMouseLeave2}>
              <iframe src={videoSrc2} width="640" height="480" allow="autoplay"></iframe>
              {!isHovering && <div className="video-overlay"></div>}
            </div>
          ) : (
            <img src={imageSrc2} alt="Image 2" onMouseEnter={handleMouseEnter2} />
          )}
        </div>
      )}
    </>
  );
};

export default VerbalReasoningQuestion;
