import React from "react";
import "../css/HowItWorksSection.css"; // Ensure you use the correct path for your CSS file
import { Helmet } from "react-helmet"; // Add this line inside the imports section

function HowItWorksSection() {
  return (
    <section className="how-it-works-section">
      <Helmet>
        <title>How It Works - GMAT Preparation</title>
        <meta
          name="description"
          content="Discover how our AI-driven platform revolutionizes GMAT preparation. Explore the steps, from creating a personalized account to accessing free GMAT practice tests and upgrading to premium for personalized feedback."
        />
        <meta
          name="keywords"
          content="GMAT preparation, AI-driven platform, personalized account, free GMAT practice tests, premium subscription, GMAT preparation steps"
        />
        {/* Add other relevant meta tags */}
      </Helmet>
      <div className="top-section">
        <div className="video-placeholder">
          <iframe src="https://www.youtube.com/embed/j8CZKJ-0reY?vq=hd720" allow="autoplay"></iframe>{" "}
        </div>
        <div className="description-section">
          <h2>How does it work?</h2>
          <p>
            Explore how our AI-driven platform revolutionizes GMAT preparation. From creating a personalized account to accessing a wealth of
            resources, we guide you through each step towards achieving your highest score.
          </p>
        </div>
      </div>
      <div className="steps-section">
        <div className="step">
          <h3>01</h3>
          <h4>Create Your Personal Account</h4>
          <p>
            Start your journey by signing up for a free account. Gain immediate access to our user-friendly platform, designed to streamline your GMAT
            prep experience.
          </p>
        </div>
        <div className="step">
          <h3>02</h3>
          <h4>Engage with Free Practice GMAT Tests</h4>
          <p>
            Dive into our comprehensive range of free GMAT practice tests. These tests are optimized to mirror the real exam, providing you with a
            realistic test-taking experience. Enhance your preparation with detailed analytics and insights.
          </p>
        </div>
        <div className="step">
          <h3>03</h3>
          <h4>Upgrade to Premium for Personalized Feedback</h4>
          <p>
            Elevate your learning with our premium subscription. Get personalized feedback, access to a wider array of tailored questions, and
            adaptive learning strategies that focus on your specific needs, helping you target your weak areas effectively.
          </p>
        </div>
      </div>
    </section>
  );
}

export default HowItWorksSection;
