import React, { useState, useContext, useEffect } from "react";
import { API_URL } from "../config/config.index";
import "../css/pricingcard.css";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const Plans = () => {
  const [data, setdata] = useState(undefined);
  const [isLoading, setisLoading] = useState(false);
  const { isPremiumMember, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const checkLoggedInAndNavigate = (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      toast.error("Log in first", {
        onClose: () => navigate("/login"),
      });
    }
  };

  const getProducts = async () => {
    setisLoading(true);
    try {
      const response = await fetch(API_URL + "/stripe/products", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) return alert("Cannot get product");

      const result = await response.json();
      setdata(result.sort((a, b) => a.unit_amount - b.unit_amount));
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  if (isPremiumMember) {
    return <h1>You are already a premium user</h1>;
  }

  return (
    <section className="pricing_section">
      <Helmet>
        <title>GMAT Practice Plans - Maximize Your Test Score</title>
        <meta
          name="description"
          content="Explore our tailored GMAT practice plans. From comprehensive study materials to full-length practice tests, we have everything you need to ace the GMAT. Choose from our premium GMAT preparation packages and start your journey to success today."
        />
        <meta name="keywords" content="GMAT practice, GMAT preparation, GMAT study plans, GMAT online course, GMAT premium packages" />
        {/* Add other relevant meta tags */}
      </Helmet>

      <h1 className="gmat-header">Strategically Outperform: The GMAT Success Formula Redefined</h1>
      {/* Subtitle added below the h1 */}
      <p className="h1-subtitle">
        Discover Your Personalized Path with Our Adaptive GMAT Study Framework, Designed to Elevate Your Score Beyond Expectations."
      </p>
      <p className="special-offer">
        Enjoy all premium features for free until our Beta phase concludes in Q1 2024. Start your journey to GMAT success today!
      </p>

      <div className="container">
        <div className="row pricing_info">
          {data && data.length ? (
            <>
              {data.map((price) => (
                <div key={price.id} className={`col-lg-4 col-md-6 ${data.length === 1 ? "single-product" : ""}`}>
                  <div className="pricing_inner_content">
                    <h4>{price.product.name}</h4>
                    <p style={{ marginBottom: "10px" }}>{price.product.description}</p>
                    <h5>
                      €{(price.unit_amount / 100).toFixed(0)}
                      <span>/{price.recurring.interval}</span>
                    </h5>
                    <div className="a-pricing-button-wrapper">
                      <NavLink to={`/payment?productId=${price.id}`} onClick={(e) => checkLoggedInAndNavigate(e)}>
                        Start now
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                          <path
                            d="M10.4863 18C14.9046 18 18.4863 14.4183 18.4863 10C18.4863 5.58172 14.9046 2 10.4863 2C6.06805 2 2.48633 5.58172 2.48633 10C2.48633 14.4183 6.06805 18 10.4863 18ZM7.23633 9.25C6.82211 9.25 6.48633 9.58579 6.48633 10C6.48633 10.4142 6.82211 10.75 7.23633 10.75H11.8264L9.72599 12.7004C9.42246 12.9823 9.40488 13.4568 9.68673 13.7603C9.96859 14.0639 10.4431 14.0814 10.7467 13.7996L14.2467 10.5496C14.3995 10.4077 14.4863 10.2086 14.4863 10C14.4863 9.79145 14.3995 9.59231 14.2467 9.4504L10.7467 6.2004C10.4431 5.91855 9.96859 5.93613 9.68673 6.23966C9.40488 6.54319 9.42246 7.01774 9.72599 7.2996L11.8264 9.25H7.23633Z"
                            fill="white"
                          />
                        </svg>
                      </NavLink>
                    </div>
                    <ul className="pricing_listing_items">
                      {price.product.metadata?.features ? (
                        price.product.metadata?.features.split(",").map((feature, i) => (
                          <li key={i} className="price_list_content">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                                fill="#1e78ff"
                              ></path>
                            </svg>
                            <span>{feature}</span>
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>{isLoading ? <Loader /> : <></>}</>
          )}
        </div>
      </div>
    </section>
  );
};

export default Plans;
