// src/components/PageTracker.jsx
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ga4Event } from "../utils/ga4"; // Ensure the path is correct based on your project structure

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ga4Event("page_view", { page_path: location.pathname + location.search });
  }, [location]);

  return null;
};

export default PageTracker;
