// Footer.js
import React from "react";

const styles = {
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    color: "#333333",
    padding: "50px 10px",
    marginTop: "auto",
    borderTop: "1px solid #D3D3D3",
  },
  contact: {
    marginTop: "20px",
  },
  mailto: {
    color: "#333333",
    textDecoration: "none",
  },
};

function Footer() {
  return (
    <footer style={styles.footer}>
      <p>© 2023 GMAT Practice. All Rights Reserved.</p>
      <p style={styles.contact}>
        Question or suggestion? Contact:{" "}
        <a href="mailto:question@casestudygenerator.com" style={styles.mailto}>
          question@casestudygenerator.com
        </a>
      </p>
    </footer>
  );
}

export default Footer;
