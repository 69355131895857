import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faBook, faCalculator, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import "../css/Navbar.css";
import Stars from "../assets/stars (1).png";

const DropdownItem = ({ icon, text, subText, link, isPremium, closeMenu }) => (
  <Link to={link} onClick={closeMenu} className="dropdownLink">
    <div className="iconColumn">
      <FontAwesomeIcon icon={icon} className="iconStyle" />
    </div>
    <div className="textColumn">
      <div className="mainText">{text}</div>
      <div className="dropdownSubtext">{subText}</div>
    </div>
  </Link>
);

function Navbar({ isAnnouncementVisible }) {
  const { isLoggedIn, logOutUser, isPremium } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when resizing to a wider screen
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <nav className="navBarStyle" style={{ top: location.pathname === "/" && isAnnouncementVisible ? "45px" : "0" }}>
        <Link className="logoStyle" to="/" onClick={() => setIsMenuOpen(false)}>
          GMAT PRACTICE
        </Link>

        {/* Hamburger Menu Icon */}
        <div className="hamburgerMenu" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="mobileMenu">
            <button className="closeMenu" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {/* Mobile menu links */}
            <Link to="/free-practice-verbal-reasoning" onClick={toggleMenu}>
              Verbal Reasoning
            </Link>
            <Link to="/free-practice-quantitative-reasoning" onClick={toggleMenu}>
              Quantitative Reasoning
            </Link>
            <Link to="/gmat-exam-selection" onClick={toggleMenu}>
              GMAT Exam
            </Link>
            {/* More links as needed */}
          </div>
        )}

        <div className="centeredItems">
          {/* Dropdown for solutions */}
          <div
            className="dropdownStyle"
            onClick={() => {
              /* logic to handle dropdown */
            }}
          >
            <div className="navLinkStyle">Practice</div>
            <div className="dropdownMenuStyle">
              <DropdownItem
                icon={faBook}
                text="Verbal Reasoning"
                subText="Sharpen your verbal skills"
                link="/free-practice-verbal-reasoning"
                closeMenu={() => setIsMenuOpen(false)}
              />
              <DropdownItem
                icon={faCalculator}
                text="Quantitative Reasoning"
                subText="Enhance your quantitative skills"
                link="/free-practice-quantitative-reasoning"
                closeMenu={() => setIsMenuOpen(false)}
              />
              <DropdownItem
                icon={faPencilAlt} // replace with the icon you want to use for GMAT exams
                text="GMAT Exams (In Development)"
                subText="Prepare for your GMAT exams"
                link="/gmat-exam-selection"
                closeMenu={() => setIsMenuOpen(false)}
              />
            </div>
          </div>

          {/* Other links */}
          <Link className="navLinkStyle" to="/plans" onClick={() => setIsMenuOpen(false)}>
            Pricing
          </Link>
          <Link className="navLinkStyle" to="/about" onClick={() => setIsMenuOpen(false)}>
            About
          </Link>
          {isLoggedIn && (
            <Link className="navLinkStyle" to="/dashboard" onClick={() => setIsMenuOpen(false)}>
              My Dashboard
            </Link>
          )}
        </div>

        <div className="rightItems">
          {isLoggedIn ? (
            <>
              <Link className="navLinkStyle" to="/profile" onClick={() => setIsMenuOpen(false)}>
                Profile
              </Link>
              <button
                onClick={() => {
                  logOutUser();
                  setIsMenuOpen(false);
                }}
                className="logoutButtonStyle"
              >
                Logout
              </button>

              {!isPremium && (
                <Link to="/plans" className="upgradeButtonStyle" onClick={() => setIsMenuOpen(false)}>
                  <img src={Stars} alt="Stars" className="starsStyle" />
                  Upgrade to Premium
                </Link>
              )}
            </>
          ) : (
            <>
              <Link className="navLinkStyle" to="/login" onClick={() => setIsMenuOpen(false)}>
                Login
              </Link>
              <button className="startFreeButtonStyle" onClick={() => navigate("/choose")}>
                Start for Free
              </button>
            </>
          )}
        </div>
      </nav>

      {/* Backdrop for mobile menu */}
      {isMenuOpen && <div className="backdrop" onClick={toggleMenu}></div>}
    </>
  );
}

export default Navbar;
