import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_URL, API_URL_NEW_BACKEND } from "../config/config.index.js";
import { useNavigate } from "react-router-dom";
import { marked } from "marked"; // Named import for 'marked'
import DOMPurify from "dompurify";
import "../css/Chatbot.css";
import { useContext } from "react";
import { AuthContext } from "../context/auth.context";
import { toast } from "react-toastify";
import carlImage from "../assets/Carl_Case.png";

function CarlCase() {
  const navigate = useNavigate();

  const [audioUrl, setAudioUrl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ttsData, setTtsData] = useState(null);
  const { isLoggedIn, user, isPremiumMember } = useContext(AuthContext);
  const [userHasCreatedFreeCase, setUserHasCreatedFreeCase] = useState(false);

  const [userPlan, setUserPlan] = useState("");
  const messagesEndRef = useRef(null);

  // ... rest of the component logic ...

  // useEffect(() => {
  //   async function fetchUserPlan() {
  //     if (!isLoggedIn || !user || !user.email) {
  //       toast.error("You must be logged in to access this page");
  //       navigate("/login");
  //       return;
  //     }

  //     try {
  //       setLoading(true);

  //       // Fetch Stripe Customer ID
  //       const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
  //       const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

  //       if (!stripeCustomerId) {
  //         console.warn("No Stripe customer ID found for user:", user.email);
  //         toast.error("You need a Basic Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       // Fetch Subscription Details
  //       const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
  //       const planName = productResponse.data.productName;
  //       if (planName !== "Basic Plan") {
  //         toast.error("You need a Basic Plan to access this page");
  //         navigate("/plans");
  //         return;
  //       }

  //       setUserPlan(planName);
  //     } catch (error) {
  //       console.error("Error fetching user plan:", error);
  //       navigate("/error");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchUserPlan();
  // }, [isLoggedIn, user, navigate]);

  const handleSend = async () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput === "") {
      console.error("User input is empty");
      return;
    }

    setMessages((prevMessages) => [...prevMessages, { user: "user", text: trimmedInput }]);
    setUserInput("");

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL_NEW_BACKEND}/api/assistant-chat`, { userMessage: trimmedInput });
      console.log("Response from backend:", response.data);

      // Use `marked` to convert Markdown to HTML and `DOMPurify` to sanitize it
      const safeHtml = DOMPurify.sanitize(marked(response.data.response));

      setMessages((prevMessages) => [...prevMessages, { user: "assistant", text: safeHtml, isMarkdown: true }]);
      setTtsData(response.data.ttsData); // Set TTS data
    } catch (error) {
      console.error("Error while fetching data from backend:", error);
      setMessages((prevMessages) => [...prevMessages, { user: "bot", text: "Error: Unable to fetch response." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return (
    <div className="chatbot-container">
      <div className="introduction-container">
        <div className="introduction-text">
          <h2>Embark on a Dynamic Case-Solving Journey with Carl!</h2>
          <p>
            Welcome to a world of interactive case-solving with Carl, your digital guide in the complex universe of business scenarios. Whether you're
            keen on dissecting market entry strategies in tech, analyzing profitability in the public sector, or exploring other industry challenges,
            Carl is here to spark your curiosity, steer your analysis, and enrich your understanding with insightful feedback.
          </p>
          <p>
            Ready to boost your problem-solving skills? Just let Carl know what you're curious about, like "I'm interested in a market entry case in
            the healthcare sector." No matter the industry – from renewable energy to finance – we've got a case for you. Let's dive in and solve
            these cases together!
          </p>
        </div>
        <div className="introduction-image">
          <img src={carlImage} alt="Carl" />
        </div>
      </div>

      <div className="messages-container" aria-live="polite">
        {messages.map((message, index) =>
          message.isMarkdown ? (
            <div key={index} className={`chat-message ${message.user}`} dangerouslySetInnerHTML={{ __html: message.text }} />
          ) : (
            <p key={index} className={`chat-message ${message.user}`}>
              {message.text}
            </p>
          )
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="user-input-container">
        <input
          className="user-input"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} disabled={loading || userInput.trim() === ""}>
          Send
        </button>
      </div>
    </div>
  );
}

export default CarlCase;
