import React, { useState } from "react";
import "../css/QuestionForExam.css";

const QuestionForExam = ({ question, onSubmitAnswer, selectedAnswer, setSelectedAnswer }) => {
  const handleAnswerChange = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleSubmit = () => {
    onSubmitAnswer(question.id, selectedAnswer);
    setSelectedAnswer(null); // Reset the selected answer for the next question
  };

  // Prevent default form submission behavior
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  // Handle different structures for question text
  const questionText = question.content ? question.content.text : question.question;

  // Function to render text with line breaks
  const renderTextWithLineBreaks = (text) => {
    // Split text on every newline and map through the parts
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line} {/* Render the line of text */}
        {index !== text.split("\n").length - 1 && <br />} {/* Add a <br/> after each line except the last */}
      </React.Fragment>
    ));
  };

  // Convert options object or array to a uniform array structure
  const optionsArray = Array.isArray(question.options)
    ? question.options
    : question.options && typeof question.options === "object"
    ? Object.entries(question.options).map(([label, text]) => ({ label, text }))
    : [];

  const handleOptionChange = (event) => {
    setSelectedAnswer(event.target.value); // Update the selected answer state
    onSubmitAnswer(question.id, event.target.value); // Submit answer
  };
  return (
    <div className="question-for-exam">
      <form onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <legend className="question-text">
            {question.content.text ? renderTextWithLineBreaks(question.content.text) : <p>Question content is unavailable.</p>}
          </legend>
          {question.options.length > 0 ? (
            question.options.map((option, index) => (
              <label key={index} className={`answer-option ${selectedAnswer === option.label ? "selected-answer" : ""}`}>
                <input type="radio" name="answer" value={option.label} checked={selectedAnswer === option.label} onChange={handleOptionChange} />
                {option.text}
              </label>
            ))
          ) : (
            <p>Error: No options available for this question.</p>
          )}
        </fieldset>
      </form>
    </div>
  );
};
export default QuestionForExam;
