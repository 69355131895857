import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth.context";
import axios from "axios";
import { API_URL } from "../config/config.index";

function TestSubscription() {
  const { user, isLoading } = useContext(AuthContext);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading) return;

    async function fetchData() {
      if (!user || !user.email) return;

      try {
        const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
        const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

        const productsResponse = await axios.get(`${API_URL}/stripe/user-subscription-details`, { params: { stripeCustomerId } });
        setProducts(productsResponse.data.products);
      } catch (error) {
        console.log("Error fetching subscription details:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [user, isLoading]);

  if (loading || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {products.length ? (
        <div>
          <p>You are subscribed to the following products:</p>
          <ul>
            {products.map((product) => (
              <li key={product.id}>{product.name}</li>
            ))}
          </ul>
          <a href="https://billing.stripe.com/p/login/4gwbJygdH30n2aYfYY">Go to billing</a>
        </div>
      ) : (
        <div>You are not subscribed to any products.</div>
      )}
    </div>
  );
}

export default TestSubscription;
