import React from "react";
import { Link } from "react-router-dom";
import "../css/AboutUs.css";
import { Helmet } from "react-helmet";
import { FaRocket, FaBullseye, FaGlobe } from "react-icons/fa"; // Use react-icons for better SEO and accessibility

const AboutUs = () => {
  return (
    <section className="about-us-section" aria-labelledby="about-title">
      <h1 id="about-title" className="about-us-title">
        About GMAT Practice
      </h1>
      <Helmet>
        <title>About GMAT Practice - Your Pathway to Success on the GMAT</title>
        <meta
          name="description"
          content="Discover GMAT Practice, the premier online resource for GMAT preparation. Get access to comprehensive GMAT practice tests, adaptive learning materials, and personalized feedback. Achieve your highest potential on the GMAT exam with GMAT Practice."
        />
      </Helmet>

      <article className="about-us-content">
        <header className="about-us-header">
          <h2 className="about-us-subtitle">Your Pathway to Success on the GMAT</h2>
        </header>
        <p className="about-us-description">
          Dive into your GMAT preparation journey with GMAT Practice. Our platform offers a rich collection of resources, designed to enhance critical
          skills assessed by the GMAT - quantitative reasoning, verbal skills, analytical writing, and integrated reasoning. We cater to a diverse
          range of learning needs, whether you're eyeing a top MBA program, a specialized master's course, or other academic endeavors.
        </p>
        <section className="about-us-solution">
          <h2 className="about-us-subtitle">What We Offer</h2>
          <p className="about-us-description">
            Advance your preparation with our comprehensive suite of tools. Our offerings include rigorous practice tests, adaptive learning
            materials, and personalized feedback, all structured to align with the GMAT's format and difficulty. Our adaptive practice questions are
            crafted to meet you at your level and challenge you to grow.
          </p>
        </section>
      </article>
      <section className="about-us-mission-vision">
        <div className="about-us-item">
          <FaRocket className="about-us-icon" aria-hidden="true" />
          <h3 className="about-us-subtitle">Our Approach</h3>
          <p className="about-us-description">
            A dynamic and adaptive learning system, tailored to each student's journey, ensuring efficient and effective GMAT preparation.
          </p>
        </div>
        <div className="about-us-item">
          <FaBullseye className="about-us-icon" aria-hidden="true" />
          <h3 className="about-us-subtitle">Our Mission</h3>
          <p className="about-us-description">
            Our mission is to provide an all-encompassing GMAT prep solution, accessible to all aspirants, fostering their success in the examination
            regardless of their academic or professional goals.
          </p>
        </div>
        <div className="about-us-item">
          <FaGlobe className="about-us-icon" aria-hidden="true" />
          <h3 className="about-us-subtitle">Our Vision</h3>
          <p className="about-us-description">
            To be recognized as the premier online resource for GMAT preparation, empowering a global community of learners to achieve their highest
            potential.
          </p>
        </div>
      </section>
      <Link to="/choose" className="about-us-cta-link">
        <button className="about-us-button" aria-label="Begin your GMAT preparation with GMAT Practice">
          Begin Your Prep
        </button>
      </Link>
    </section>
  );
};

export default AboutUs;
