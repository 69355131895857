import React from "react";
import { FaUserAlt, FaChartLine, FaDatabase, FaQuestionCircle, FaRobot } from "react-icons/fa";
import "../css/SocialProof.css";
import carlImage from "../assets/Carl_Case.png";
import Social_free from "../assets/Social_free.jpg";
import Social_AI from "../assets/Social_AI.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function SocialProof() {
  return (
    <section className="social-proof-section">
      <Helmet>
        <title>GMAT Preparation - Transform Your GMAT Prep</title>
        <meta
          name="description"
          content="Discover how our cutting-edge features can transform your GMAT preparation. Get unlimited free GMAT questions, AI-driven answers, and personalized feedback to conquer the GMAT with confidence."
        />
        <meta name="keywords" content="GMAT preparation, GMAT practice, GMAT features, GMAT AI, personalized feedback, GMAT preparation resources" />
        {/* Add other relevant meta tags */}
      </Helmet>
      <div className="social-proof-title-container">
        <h2 className="social-proof-title">Transform Your GMAT Preparation with Our Cutting-edge Features</h2>
      </div>
      <div className="social-proof-grid">
        {/* Feature Item 1 */}

        <div className="social-proof-item">
          <FaQuestionCircle className="social-proof-icon" />
          <h3 className="social-proof-item-title">+2000 Free GMAT Questions</h3>
          <p className="social-proof-item-description">
            Elevate your GMAT prep without spending a dime! Enjoy unrestricted access to a diverse range of GMAT questions, covering all sections of
            the test. Perfect your strategy, enhance your understanding, and get ready to conquer the GMAT with confidence.
          </p>
          <div className="social-proof-item-image-container">
            <img src={Social_free} alt="Student preparing for GMAT" />
          </div>
        </div>

        {/* Feature Item 2 */}
        <div className="social-proof-item">
          <FaRobot className="social-proof-icon" />
          <h3 className="social-proof-item-title">AI-Driven Answers and Personalized Feedback</h3>
          <p className="social-proof-item-description">
            Step into the future of GMAT prep with our AI-powered platform. Get instant, detailed feedback on your answers, personalized tips to
            improve, and smart recommendations that guide you towards the 700 club. Our AI mentor is your secret weapon to mastering the GMAT.
          </p>
          <div className="social-proof-item-image-container">
            <img src={Social_AI} alt="Student receiving instant feedback from an AI assistant" />
          </div>
        </div>

        {/* Future Feature Item 3 */}
        {/* <div className="social-proof-item">
  <FaMedal className="social-proof-icon" />
  <h3 className="social-proof-item-title">Exclusive Access to Official GMAT Tests</h3>
  <p className="social-proof-item-description">
    Coming soon! Get the ultimate preparation edge with access to official GMAT tests. Experience the real test environment, understand the official question patterns, and benchmark your preparation accurately. Stay tuned for this game-changing feature!
  </p>
  <div className="social-proof-item-image-container">
    {/* Image of official GMAT test materials and a countdown timer indicating the upcoming release */}
        {/* </div>
</div> */}
      </div>
      <div className="social-proof-cta-container">
        <h3 className="centered-text">Ready to Ace the GMAT?</h3>
        <p className="centered-text">
          Join a community of aspirants who are revolutionizing their GMAT preparation journey. With our innovative tools and personalized approach,
          achieving your dream score is closer than ever.
        </p>
        <div className="social-proof-buttons">
          <Link to="/choose" className="social-proof-btn-primary">
            Get Started
          </Link>
          <Link to="/features" className="social-proof-btn-secondary">
            Explore All Features
          </Link>
        </div>
      </div>
    </section>
  );
}

export default SocialProof;
