import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { API_URL_NEW_BACKEND } from "../config/config.index.js";
import { useLocation } from "react-router-dom";
import "../css/ScoreComponent.css";
import { AuthContext } from "../context/auth.context";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// ScoreComponent is a component that calculates and displays the user's score after they finish the exam
const ScoreComponent = () => {
  // useLocation is a hook from react-router-dom that provides access to the location object
  // The location object represents where the app's current location is, where you’d like it to be, or even where it was.
  const location = useLocation();
  const navigate = useNavigate();

  // userAnswers and userId are extracted from the location state
  const { userAnswers, attempts, totalQuestions, questionTypeSummaries } = location.state || {};

  console.log("User Answers:", userAnswers);

  console.log("Attempts:", attempts);
  console.log("Total Questions:", totalQuestions);
  console.log("Question Type Summary in ScoreComponent:", questionTypeSummaries);

  // feedback, totalScore, and scores are state variables that are initialized with useState
  const [feedback, setFeedback] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const { user } = useContext(AuthContext); // Extract userId from AuthContext
  const [scores, setScores] = useState({
    arithmetic: 0,
    algebra: 0,
    dataSufficiency: 0,
    sentenceCorrection: 0,
    readingComprehension: 0,
    criticalReasoning: 0,
  });

  const userId = user._id;

  // calculateScores is a function that calculates the scores for each category and the total score
  const calculateScores = () => {
    let newScores = {
      Arithmetic: 0,
      Algebra: 0,
      "Data Sufficiency": 0,
      "Sentence Correction": 0,
      "Reading Comprehension": 0,
      "Critical Reasoning": 0,
    };

    if (userAnswers && Array.isArray(userAnswers)) {
      console.log("userAnswers:", userAnswers); // Log the initial userAnswers array

      userAnswers.forEach((answer, index) => {
        console.log(`Processing answer ${index + 1}:`, answer); // Log each answer being processed

        let category = answer.questionType || "Unknown";
        console.log(`Answer category: ${category}`); // Log the category of each answer
        console.log(`Processing: ${category}, Correct: ${answer.isCorrect}`); // Logging for debugging

        if (answer.isCorrect && newScores.hasOwnProperty(category)) {
          console.log(`Answer is correct. Incrementing score for category ${category}.`); // Log when a score is incremented
          newScores[category] += 1;
        } else {
          console.log(`Answer is not correct or category ${category} does not exist in newScores.`); // Log when a score is not incremented
        }
      });

      console.log("newScores after processing answers:", newScores); // Log the newScores object after processing all answers

      let newTotalScore = Object.values(newScores).reduce((a, b) => a + b, 0);
      console.log("newTotalScore:", newTotalScore); // Log the new total score

      setScores(newScores);
      setTotalScore(newTotalScore);
    } else {
      console.error("userAnswers is not an array or is undefined");
    }
  };

  // viewExamDetails is a function that navigates to the ExamInsight component
  const viewExamDetails = (examId) => {
    // Navigate to the ExamInsight component with the examId
    navigate(`/exam-insight/${examId}`);
  };

  // useEffect is a hook that runs side effects, such as data fetching or manually changing the DOM
  // In this case, it's used to calculate the scores when the userAnswers state changes
  useEffect(() => {
    if (!Array.isArray(userAnswers)) {
      console.error("userAnswers should be an array, received:", userAnswers);
      return;
    }
    calculateScores();
    console.log("Updated answers state:", userAnswers);
  }, [userAnswers]); // Dependency array includes userAnswers to trigger re-calculation

  const transformQuestionTypeSummaries = (summariesObject) => {
    return Object.entries(summariesObject).map(([questionType, { total, correct }]) => ({
      questionType,
      total,
      correct,
    }));
  };

  const formattedQuestionTypeSummaries = transformQuestionTypeSummaries(questionTypeSummaries);
  console.log("Formatted question Score:", formattedQuestionTypeSummaries);

  const submitScores = async () => {
    console.log("Submitting scores:", { userId, scores, totalScore, totalQuestions, questionTypeSummaries, attempts });

    try {
      const response = await axios.post(
        `${API_URL_NEW_BACKEND}/getexams/examresults`,
        {
          userId: userId,
          scores,
          totalScore,
          totalQuestions,
          attempts,
          questionTypeSummaries: formattedQuestionTypeSummaries,
          feedback,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Saved exam data:", response.data);
      navigate("/dashboard"); // Navigate to /dashboard
    } catch (error) {
      console.error("Error saving exam data:", error);
    }
  };

  return (
    <div className="score-container">
      <h2 className="score-header">Your Score</h2>
      <div className="score-details">
        <p className="score-category">Arithmetic: {scores.Arithmetic}</p>
        <p className="score-category">Algebra: {scores.Algebra}</p>
        <p className="score-category">Data Sufficiency: {scores["Data Sufficiency"]}</p>
        <p className="score-category">Sentence Correction: {scores["Sentence Correction"]}</p>
        <p className="score-category">Reading Comprehension: {scores["Reading Comprehension"]}</p>
        <p className="score-category">Critical Reasoning: {scores["Critical Reasoning"]}</p>
      </div>
      <p className="total-score">Total Score: {totalScore}</p>
      <textarea
        className="feedback-input"
        placeholder="Enter what you struggled with here..."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />

      <button className="submit-score-btn" onClick={submitScores}>
        Submit Score
      </button>
    </div>
  );
};

export default ScoreComponent;
