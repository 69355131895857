import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { API_URL_NEW_BACKEND } from "../config/config.index";
import { Helmet } from "react-helmet";
import { AuthContext } from "../context/auth.context";
import { PieChart, Pie, Sector, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [examData, setExamData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const { isLoggedIn, user } = useContext(AuthContext);

  useEffect(() => {
    const tailwindLink = document.createElement("link");
    tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
    tailwindLink.rel = "stylesheet";
    document.head.appendChild(tailwindLink);
    const fetchExamData = async () => {
      try {
        const response = await axios.get(`${API_URL_NEW_BACKEND}/getexams/allexams`);
        const fetchedData = response.data; // Assuming this is the array of exams
        const transformedPieData = generatePieData(fetchedData);
        setExamData(fetchedData); // Set the raw exam data
        setPieData(transformedPieData); // Set the transformed data for rendering
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };

    fetchExamData();
  }, []);

  // Function to transform exam data into pie chart format
  const generatePieData = (exams) => {
    let aggregatedQuestionTypes = {};

    exams.forEach((exam) => {
      exam.questionTypeSummaries.forEach((qType) => {
        // Ensure that all question types, including "Data Sufficiency", are included
        if (!aggregatedQuestionTypes[qType.questionType]) {
          aggregatedQuestionTypes[qType.questionType] = { total: 0, correct: 0 };
        }
        aggregatedQuestionTypes[qType.questionType].total += qType.total;
        aggregatedQuestionTypes[qType.questionType].correct += qType.correct;
      });
    });

    // Convert aggregated data to pie chart format
    let transformedPieData = Object.entries(aggregatedQuestionTypes).map(([questionType, data]) => {
      const percentage = (data.correct / data.total) * 100; // Correct calculation using data from questionTypeSummary
      return {
        name: questionType,
        percentage: Number(percentage.toFixed(2)),
      };
    });

    return transformedPieData;
  };

  const navigate = useNavigate();

  const viewExamDetails = (examId) => {
    navigate(`/exam-insight/${examId}`);
  };

  const getLowestScoringQuestionTypes = (data) => {
    const sortedData = [...data].sort((a, b) => a.percentage - b.percentage);
    return sortedData.slice(0, 2);
  };

  const lowestScoringQuestionTypes = pieData.length > 0 ? getLowestScoringQuestionTypes(pieData) : [];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#85144b"];

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <Helmet>
        <title>Exam Dashboard - Track Your GMAT Progress</title>
        {/* Meta tags */}
      </Helmet>

      {/* Header Section */}
      {/* <header className="bg-indigo-600 text-white p-6 shadow">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold">Exam Dashboard</h1>
          {isLoggedIn && <button className="bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded">Add New Exam</button>}
        </div>
      </header> */}

      {/* Main Content Section */}
      <main className="container mx-auto p-6 space-y-8 ">
        {/* Welcome message or summary */}
        <section className="text-center p-8 shadow-lg rounded bg-white mt-20">
          <h2 className="text-2xl font-bold text-gray-800">Welcome Back, {user?.name || "User"}!</h2>
          <p className="text-gray-600 mt-4 text-center">
            Every GMAT journey is unique, just like your goals. We're here to support your specific score target. Help us tailor your dashboard by
            telling us which metrics matter most to you. Together, let's pave the way to your success.
          </p>
          <p className="mt-4 text-center">
            Share your thoughts at{" "}
            <a
              href="mailto:question@casestudygenerator.com"
              className="text-indigo-600 hover:text-indigo-800 transition duration-300 ease-in-out underline"
            >
              question@casestudygenerator.com
            </a>
          </p>
        </section>

        {/* Pie Chart Section for showing score distribution */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Score Distribution</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="percentage" // key for the percentage value
                nameKey="name" // key for the name
                label={({ name, percentage }) => `${name}: ${percentage}%`} // Customize the label
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          {pieData.length > 0 && (
            <div className="flex justify-center items-center bg-blue-100 text-blue-800 p-4 rounded-md shadow-sm mt-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 mr-2 text-blue-500">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <p className="text-sm font-medium">
                We recommend practicing more on: {lowestScoringQuestionTypes.map((qType) => qType.name).join(", ")}
              </p>
            </div>
          )}
        </div>

        {/* Exam Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {examData.length > 0 ? (
            examData.map((exam, index) => {
              const totalPercentage = (exam.totalScore / exam.totalQuestions) * 100;
              return (
                <div
                  key={index}
                  className="bg-white overflow-hidden shadow rounded-lg transition duration-500 hover:shadow-lg hover:bg-blue-100 transform hover:scale-105 cursor-pointer"
                  onClick={() => viewExamDetails(exam._id)}
                >
                  <div className="p-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Exam on {new Date(exam.dateTaken).toLocaleDateString()}</h3>
                        <p className="mt-1 text-base text-gray-500">
                          Total Score: {exam.totalScore} ({totalPercentage.toFixed(2)}%)
                        </p>
                      </div>
                      <div className="inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold bg-green-100 text-green-800">
                        {exam.status}
                      </div>
                    </div>
                    <div className="mt-4">
                      {exam.questionTypeSummaries.map((qType, idx) => {
                        const questionTypePercentage = ((qType.correct / qType.total) * 100).toFixed(2);
                        return (
                          <p key={idx} className="text-sm font-medium text-gray-700 mb-1">
                            {qType.questionType}:{" "}
                            <span className="font-semibold">
                              {qType.correct}/{qType.total}
                            </span>{" "}
                            ({questionTypePercentage}%)
                          </p>
                        );
                      })}
                      {exam.feedback && <p className="mt-4 text-sm text-gray-600">Remarks: {exam.feedback}</p>}
                    </div>
                  </div>
                  <div className="border-t border-gray-200">
                    <div className="flex justify-between items-center p-4 hover:bg-blue-50">
                      <span className="text-sm text-gray-600">Analyze Your Performance & Gain Insights</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center col-span-3">
              <p>No exam data available. Start taking exams to track your progress!</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
