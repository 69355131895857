/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import BlogPost from "./BlogPost";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";

const blogsContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
`;

const searchContainerStyle = css`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const searchInputStyle = css`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 300px;
`;

const searchButtonStyle = css`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #0070c0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #005499;
  }
`;

const blogsRowStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function Blogs({ posts }) {
  const [searchValue, setSearchValue] = useState("");
  const [filteredPosts, setFilteredPosts] = useState(posts);

  useEffect(() => {
    const filteredPosts = posts.filter((post) => {
      const isMatchingTitle = post.title.toLowerCase().includes(searchValue.toLowerCase());
      return isMatchingTitle;
    });
    setFilteredPosts(filteredPosts);
  }, [searchValue, posts]);

  return (
    <div css={blogsContainerStyle}>
      <Helmet>
        <title>Learn About Case Interviews - Preparation for McKinsey, Deloitte, BCG, and More</title>
        <meta
          name="description"
          content="Get ready for case interviews with insights and tips from top consulting firms like McKinsey, Deloitte, BCG, and more. Prepare effectively to land your dream consulting job."
        />
      </Helmet>
      <div css={searchContainerStyle}>
        <input
          css={searchInputStyle}
          type="text"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder="Search..."
        />
        <button css={searchButtonStyle} onClick={() => {}}>
          Search
        </button>
      </div>

      <div css={blogsRowStyle}>
        {filteredPosts.map((post) => (
          <BlogPost key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
}

export default Blogs;
