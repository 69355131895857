import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import ProductDisplay from "./components/ProductDisplay";
import SuccessDisplay from "./components/SuccessDisplay";
import Message from "./components/Message";
import StripeCheckoutForm from "./components/StripeCheckoutForm";
import PricingPage from "./components/PricingPage";

import PracticeKnowledge from "./components/PracticeKnowledge";
import Blogs from "./components/Blogs";
import blogData from "./assets/BlogData";
import BlogPostDetail from "./components/BlogPostDetail";
import LandingPage from "./components/LandingPage";
import BuildingBlock from "./components/BuildingBlock";
import Services from "./components/Services";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import { AuthContext, AuthContextWrapper } from "./context/auth.context";
import ChoosePractice from "./components/ChoosePractice";
import PracticeSkills from "./components/PracticeSkills";
import ChatBot from "./components/ChatBot";
import AboutUs from "./components/AbousUs";
import PaymentComponent from "./components/PaymentComponent";
import ProfilePage from "./components/ProfilePage";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import PaymentForm from "./components/PaymentForm";
import Plans from "./components/Plans";

import TestSubscription from "./components/TestSubscription";
import AdminPage from "./components/AdminPage";

import AnnouncementBar from "./components/AnnouncementBar";

import SimoneSkills from "./components/SimoneSkills";
import CarlCase from "./components/CarlCase";
import ChoosePersonalCompany from "./components/ChoosePersonalCompany";
import PersonalExperienceMCK from "./components/PersonalExperienceMCK";
import PersonalExperienceBCG from "./components/PersonalExperienceBCG";
import PersonalExperienceBain from "./components/PersonalExperienceBain";
import FreeCaseStatusManager from "./components/FreeCaseStatus";
import QuantitativeQuestion from "./components/QuantitativeQuestion";
import QuantitativeQuestionnaire from "./components/QuantitativeQuestionnaire";
import VerbalReasoningQuestion from "./components/VerbalReasoningQuestion";
import VerbalQuestionnaire from "./components/VerbalQuestionnaire";
import PageTrackingWrapper from "./components/PageTrackingWrapper";
import HomePageExam from "./components/HomePageExam";
import FullGMATExam from "./components/FullGAMTExam";
import QuestionGenerator from "./components/QuestionGenerator";
import ScoreComponent from "./components/ScoreComponent";
import Dashboard from "./components/Dashboard";
import ExamInsight from "./components/ExamInsight";

// Replace "YOUR_PUBLISHABLE_STRIPE_KEY" with your actual Stripe publishable key
const stripePromise = loadStripe("pk_live_51OONh1AVldEN0oMSHM3drX7vOeCA7TmKIjCkjqjRgbzjtM1lGK7qPmxpF7D2fjpmY4zyDFITklOh8WVbMCrb1vqg00xD6ngLTo");
// const stripePromise = loadStripe("pk_test_51NZp0RSAnMBSRmgy8Nrr8XYrIavXeLjQIeUZ2LIBWeucOr7F1EqxchQrmiJfHkp2nnfXI6ZrUCux1vRlVMaakK2G00ZTuRNO2V");

export default function App() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsAnnouncementVisible(false);
      } else {
        setIsAnnouncementVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AuthContextWrapper>
      <Router>
        <PageTrackingWrapper>
          {/* <AnnouncementBar setIsAnnouncementVisible={setIsAnnouncementVisible} /> */}
          <NavBar />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/practice-knowledge" element={<PracticeKnowledge />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/plans" element={<Plans />} />
            {/* Wrap the PaymentComponent with Elements */}
            <Route
              path="/payment"
              element={
                <Elements stripe={stripePromise}>
                  {/* <PaymentComponent /> */}
                  <PaymentForm />
                </Elements>
              }
            />
            <Route path="/subscription" element={<TestSubscription />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/free-practice-quantitative-reasoning" element={<QuantitativeQuestionnaire userPlan="Basic Plan" />} />{" "}
            <Route path="/free-practice-verbal-reasoning" element={<VerbalQuestionnaire userPlan="Basic Plan" />} />{" "}
            <Route path="/blogs" element={<Blogs posts={blogData} />} />
            {/* <Route path="/blog/:blogId" element={<BlogPostDetail />} /> */}
            <Route path="/blog/:blogSlug" element={<BlogPostDetail />} />
            <Route path="/auth/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/choose" element={<ChoosePractice />} />
            <Route path="/create-GMAT-questions" element={<QuestionGenerator />} />
            <Route path="/gmat-exam-selection" element={<HomePageExam />} /> <Route path="/gmat-exam/full" element={<FullGMATExam />} />
            <Route path="/gmat-exam/Full" element={<FullGMATExam />} />
            <Route path="/gmat-exam-results" element={<ScoreComponent />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/exam-insight/:examId" element={<ExamInsight />} />
            {/* <Route path="/gmat-exam/mini" element={<MiniExamComponent />} /> */}
            <Route path="/choose-personal-experience" element={<ChoosePersonalCompany />} />
            <Route
              path="/practice-personal-experience-mckinsey"
              element={<PersonalExperienceMCK requiredPlan="Premium Plan" caseType="PersonalExperience" />}
            />
            <Route
              path="/practice-personal-experience-bcg"
              element={<PersonalExperienceBCG requiredPlan="Premium Plan" caseType="PersonalExperience" />}
            />
            <Route
              path="/practice-personal-experience-bain"
              element={<PersonalExperienceBain requiredPlan="Premium Plan" caseType="PersonalExperience" />}
            />
            <Route
              path="/practice-skills-related-to-case-interview"
              element={
                <FreeCaseStatusManager requiredPlan="Premium Plan" caseType="Simone">
                  <SimoneSkills />
                </FreeCaseStatusManager>
              }
            />
            <Route
              path="/practice-case-interview"
              element={
                <FreeCaseStatusManager requiredPlan="Basic Plan" caseType="Carl">
                  <CarlCase />
                </FreeCaseStatusManager>
              }
            />
            {/* <Route path="/openai" element={<ChatBot />} /> */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
          </Routes>
          {/* {!success && message === "" && <StripeCheckoutForm />}
        {success && sessionId !== "" && <SuccessDisplay sessionId={sessionId} />}
        {message !== "" && <Message message={message} />} */}
        </PageTrackingWrapper>
      </Router>
    </AuthContextWrapper>
  );
}
