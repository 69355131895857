import React from "react";
import { Link } from "react-router-dom";
// import "../css/Choose.css";
import VerbalReasoningImg from "../assets/Choose_verbal.png";
import QuantitativeReasoningImg from "../assets/Choose_quant.png";
import GmatExamImg from "../assets/GmatExamImg.png";
import { useEffect } from "react";

function ChoosePractice() {
  useEffect(() => {
    const tailwindLink = document.createElement("link");
    tailwindLink.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
    tailwindLink.rel = "stylesheet";
    document.head.appendChild(tailwindLink);

    return () => {
      // Remove the stylesheet when the component unmounts
      document.head.removeChild(tailwindLink);
    };
  }, []);
  const Card = ({ title, image, link, buttonText, description }) => {
    return (
      <div className="card">
        <img src={image} alt={title} />
        <div className="card-title">{title}</div>
        <div className="card-overlay">
          <p className="card-description">{description}</p>
          <Link to={link} className="card-link">
            {buttonText}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-6 grid grid-cols-1 md:grid-cols-3 gap-4 mt-20">
      {" "}
      <Card
        title="GMAT Verbal Excellence"
        image={VerbalReasoningImg}
        link="/free-practice-verbal-reasoning"
        buttonText="Start Practice"
        badge="Verbal"
        description="Ace the GMAT with our specialized Verbal Reasoning module. Develop critical reading and comprehension skills, enhance your logical reasoning, and perfect your sentence correction strategies for top GMAT scores."
      />
      <Card
        title="GMAT Quantitative Mastery"
        image={QuantitativeReasoningImg}
        link="/free-practice-quantitative-reasoning"
        buttonText="Start Practice"
        badge="Quantitative"
        description="Conquer the GMAT Quantitative section with confidence. Our module focuses on Arithmetic and Algebra, essential for achieving a high score and excelling in your MBA journey."
      />
      <Card
        title="Take The GMAT Exam"
        image={GmatExamImg} // Make sure this image is correctly referenced
        link="/gmat-exam/Full"
        buttonText="Take Exam"
        badge="Exam"
        description="Ready to take the GMAT? Enroll now to test your skills and knowledge. Prepare with our comprehensive modules and practice tests to ensure you're fully ready for exam day."
      />
      {/* ... Other Cards ... */}
    </div>
  );
}

export default ChoosePractice;
