import React from "react";
import gmatJourneyImage from "../assets/English_tutor.png"; // Update this path to your image
import "../css/AllInOneTool.css"; // Ensure this CSS file contains the necessary styles
import VerbalAllInOneTool from "../assets/Verbal_AllIneOneTool.png";
import QuantitativeAllInOneTool from "../assets/Quant_AllInOneTool.png";
import Dashboard from "./../assets/Dashboard_GMAT.png";

import { Helmet } from "react-helmet"; // Add this line inside the imports section

function AllInOneTool() {
  return (
    <div className="all-in-one-tool-container">
      <Helmet>
        <title>GMAT All-In-One Tool - Master Verbal & Quantitative Skills</title>
        <meta
          name="description"
          content="Master GMAT with confidence using our AI-driven platform. Focus on empowering your Verbal Reasoning and Quantitative skills with interactive learning experiences, data sufficiency drills, and tailored approaches to conquer the GMAT."
        />
        <meta
          name="keywords"
          content="GMAT preparation, Verbal Reasoning, Quantitative Skills, GMAT All-In-One Tool, AI-driven platform, GMAT learning"
        />
        {/* Add other relevant meta tags */}
      </Helmet>
      {/* Header Section */}
      <div className="header-section">
        <h2>Master GMAT with Confidence</h2>
        <p>
          Step into a world where conquering the GMAT is not just a dream but a reality. Our AI-driven platform is your ally in this journey, focusing
          on empowering your Verbal Reasoning and Quantitative skills to unlock your full potential.
        </p>
      </div>

      {/* Verbal Reasoning Section */}
      <div className="learning-section">
        <div className="image-container">
          <img src={VerbalAllInOneTool} alt="Verbal Reasoning Mastery" />
        </div>
        <div className="text-content">
          <div className="text-block">
            <h3>Comprehensive Verbal & Quantitative Mastery</h3>
            <p style={{ borderBottom: "1px solid white", paddingBottom: "20px" }}>
              Embark on a journey to master both verbal reasoning and quantitative skills. Our integrated approach combines the intricacies of
              language with the precision of mathematics, providing a balanced curriculum that enhances your comprehension and problem-solving
              abilities. Experience a seamless blend of reading comprehension, critical reasoning, sentence correction, and numerical prowess, all
              designed to prepare you for excellence.
            </p>
            <p style={{ paddingTop: "20px" }}>
              With our interactive platform, dive into a world where verbal elegance meets quantitative acumen. Engage with tailored learning modules
              that adapt to your pace and style, ensuring that every challenge is an opportunity for growth. Prepare to conquer both the verbal and
              quantitative sections with confidence and finesse, setting new benchmarks in your academic and professional journey.
            </p>
          </div>
        </div>
      </div>

      {/* Quantitative Skills Section */}
      <div className="learning-section">
        <div className="text-content">
          <div className="text-block">
            <h3>Targeted GMAT Preparation & Performance Insights</h3>
            <p style={{ borderBottom: "1px solid white", paddingBottom: "20px" }}>
              Enhance your GMAT preparation with our specialized offerings. Take realistic practice exams designed to mirror the actual test
              environment, providing you with the stamina and strategy needed for success. Our comprehensive dashboard analyzes your performance,
              offering personalized insights and recommendations.{" "}
            </p>
            <p style={{ paddingTop: "20px" }}>
              Understand where you excel and where you need improvement with our detailed analysis. Utilize our strategic guidance to focus your
              efforts effectively, targeting areas for improvement and reinforcing your strengths. With our expertly crafted resources and supportive
              tools at your disposal, you're not just preparing for the GMAT; you're ensuring a future filled with possibilities.
            </p>
          </div>
        </div>
        <div className="image-container">
          <img src={Dashboard} alt="Quantitative Skills Excellence" />
        </div>
      </div>
    </div>
  );
}

export default AllInOneTool;
