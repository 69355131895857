import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../css/ExamInsight.css";
import { API_URL_NEW_BACKEND } from "../config/config.index";

const ExamInsight = () => {
  const { examId } = useParams();
  const [examDetails, setExamDetails] = useState(null);

  useEffect(() => {
    const fetchExamDetails = async () => {
      try {
        // Fetching the exam details
        const { data: examData } = await axios.get(`${API_URL_NEW_BACKEND}/getexams/exams/${examId}`);
        console.log("Exam details:", examData);

        // Fetching the questions for each attempt and updating the attempt with question data
        const updatedAttempts = await Promise.all(
          examData.attempts.map(async (attempt) => {
            try {
              const { data: questionData } = await axios.get(`${API_URL_NEW_BACKEND}/getexams/questions/${attempt.questionId}`);
              console.log(`Fetched question data for questionId: ${attempt.questionId}`, questionData);
              return { ...attempt, questionData }; // Append question data to attempt
            } catch (error) {
              console.error(`Error fetching question with id ${attempt.questionId}:`, error);
              return attempt; // return attempt as is if question fetch fails
            }
          })
        );

        // Updating the exam details state with updated attempts
        setExamDetails({ ...examData, attempts: updatedAttempts });
      } catch (error) {
        console.error("Error fetching exam details: ", error);
      }
    };
    fetchExamDetails();
  }, [examId]); // Dependency on examId to refetch if it changes

  return (
    <div className="exam-insight-container">
      {examDetails ? (
        examDetails.attempts?.map((attempt, index) => (
          <div key={index} className="question-review">
            <h3>Question {index + 1}</h3>
            <p className="question">{attempt.questionData?.content?.text || "Question text not available"}</p>

            <div className="options">
              {attempt.questionData.options?.map((option, idx) => (
                <p key={idx} className={option.label === attempt.correctAnswer ? "option correct" : "option"}>
                  {option.text}
                </p>
              ))}
            </div>

            <p>
              Your Answer: <span className={attempt.isCorrect ? "answer correct" : "answer incorrect"}>{attempt.userAnswer}</span>
            </p>
            <p>
              Correct Answer: <span className="answer correct">{attempt.questionData.correctAnswer}</span>
            </p>

            {attempt.questionData.explanation && (
              <div className="explanation">
                <h4>Explanation</h4>
                <p>{attempt.questionData.explanation}</p>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>Loading exam details...</p>
      )}
    </div>
  );
};

export default ExamInsight;
