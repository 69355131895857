import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/PremiumChatInterface.css";
import ReactMarkdown from "react-markdown";

import { API_URL_NEW_BACKEND } from "../config/config.index.js";

const PremiumChatInterface = ({ userId, isPremiumUser, currentQuestion, resetChat, onResetDone, subjectArea }) => {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (resetChat) {
      setChatHistory([]);
      onResetDone(); // Notify parent that reset is complete
    }
  }, [resetChat, onResetDone]);

  const sendMessage = async (message) => {
    setIsLoading(true);
    setError(null);

    try {
      if (!currentQuestion || Object.keys(currentQuestion).length === 0) {
        throw new Error("Current question data is missing.");
      }

      console.log("Current Question: ", currentQuestion);
      const apiUrl = subjectArea === "math" ? `${API_URL_NEW_BACKEND}/api/assistant-math` : `${API_URL_NEW_BACKEND}/api/assistant-english`;

      const response = await axios.post(apiUrl, {
        userId: userId,
        userMessage: message,
        currentQuestion: currentQuestion,
      });

      return response.data.response;
    } catch (err) {
      setError("Failed to send message. Please try again.");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userInput.trim()) return;

    // Check if currentQuestion is not empty before sending
    if (!currentQuestion || Object.keys(currentQuestion).length === 0) {
      setError("No question selected.");
      return;
    }

    const newMessage = { type: "user", text: userInput };
    setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);

    const apiResponse = await sendMessage(userInput);
    if (apiResponse) {
      setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage, { type: "api", text: apiResponse }]);
    }
    setUserInput(""); // Clear the input field
  };

  if (!isPremiumUser) {
    return <div>Access to the chat interface is a premium feature.</div>;
  }

  return (
    <div className="premium-chat-interface">
      <div className="chat-history">
        {chatHistory.map((message, index) => (
          <ReactMarkdown key={index} className={`message ${message.type}`}>
            {message.text}
          </ReactMarkdown>
        ))}
      </div>
      <form className="user-input-container" onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          className="user-input"
          placeholder="Type your message here..."
        />
        <button type="submit" disabled={isLoading} className="send-button">
          Send
        </button>
      </form>
      {isLoading && <div className="loading-message">Loading...</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default PremiumChatInterface;
