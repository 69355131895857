import React from "react";
import mbaImage from "../assets/graduation-5574612_1280.jpg"; // Replace with the actual image path
import businessSchoolImage from "../assets/casual-business-mens-fashion.jpg"; // Replace with the actual image path
import careerAdvancementImage from "../assets/reading-charts-at-business-meeting.jpg"; // Replace with the actual image path
import "../css/UseCaseComponent.css"; // Make sure the CSS file path is correct
import { Helmet } from "react-helmet"; // Import Helmet

function UseCaseComponent() {
  return (
    <div className="use-case-container">
      <Helmet>
        <title>MBA Journey Support - Tailored Resources and Expert Advice</title>
        <meta
          name="description"
          content="Your one-stop platform for MBA students, business school applicants, and professionals seeking career advancement post-MBA. Get expert resources, advice, and career support tailored to your MBA journey."
        />
        <meta
          name="keywords"
          content="MBA, business school, MBA application, career advancement, MBA resources, MBA tools, MBA consulting, MBA advice"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <h2>Welcome to Your GMAT Success Path</h2>
      <div className="use-case-cards">
        {/* Card for MBA Applicants */}
        <div className="card">
          <img src={mbaImage} alt="MBA Applicants" />
          <h3>MBA Applicants</h3>
          <p>Begin your MBA journey with our expert GMAT preparation tools, designed to help achieve the score you need for the MBA.</p>
          <div className="card-action">
            <a href="/choose" className="get-started-link">
              Get Started <span className="arrow-up-right">↗</span>
            </a>
          </div>
        </div>
        {/* Card for Business Schools */}
        <div className="card">
          <img src={businessSchoolImage} alt="Business Schools" />
          <h3>Business Schools</h3>
          <p>Enhance student success with our specialized GMAT preparation resources, crafted to improve application profiles significantly.</p>
          <a href="/choose" className="get-started-link">
            Get Started <span className="arrow-up-right">↗</span>
          </a>
        </div>
        {/* Card for Career Advancement */}
        <div className="card">
          <img src={careerAdvancementImage} alt="Career Advancement" />
          <h3>Career Advancement</h3>
          <p>Make a strategic career move with our focused GMAT prep, your essential first step towards achieving professional advancement.</p>
          <a href="/choose" className="get-started-link">
            Get Started <span className="arrow-up-right">↗</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default UseCaseComponent;
