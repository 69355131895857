import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { API_URL } from "../config/config.index";
import { AuthContext } from "../context/auth.context";
import "../css/StripeForm.css";
import { NavLink, Navigate } from "react-router-dom";
import Loader from "./Loader";
import cardsimg from "../../src/assets/cards_logoimg.png";
import cvclogo from "../../src/assets/cvclogo.png";
import carimg from "../../src/assets/product-car.png";
import stockimg from "../../src/assets/andrew-neel-ute2XAFQU2I-unsplash.jpg";

function PaymentForm() {
  const [isLoading, setisLoading] = useState(false);
  const { user, isPremiumMember, setIsPremiumMember, setisStripeSubscriptionEnabled } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const [data, setdata] = useState(undefined);
  const [emaiil, setemail] = useState("");
  const [name, setname] = useState("");
  const [Dropdown, setDropdown] = useState(false);
  const [status, setStatus] = useState(false);
  const [error, seterror] = useState("");
  const [productId, setproductId] = useState(new URLSearchParams(window.location.search).get("productId"));

  const getProducts = async () => {
    const response = await fetch(API_URL + "/stripe/products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) return alert("Cannot get product");
    const result = await response.json();
    setdata(result);
  };

  useEffect(() => {
    getProducts();
    function clear(e) {
      if (!e.target.closest(".pay_btn")) {
        seterror("");
      }
    }
    document.addEventListener("click", clear);
    return () => {
      document.removeEventListener("click", clear);
    };
  }, []);
  if (!productId) {
    return <Navigate to="/plans" replace={true} />;
  }
  if (!user) {
    return <Navigate to="/login" replace={true} />;
  }

  const createSubscription = async (e) => {
    e.preventDefault();
    console.log("Creating subscription...");
    setisLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      console.error("CardNumberElement not found");
      seterror("Card number element not found");
      setisLoading(false);
      return;
    }

    try {
      console.log("Creating payment method...");
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      if (error) {
        console.error("Error creating payment method:", error);
        seterror(error.message || "Failed to create payment method.");
        setisLoading(false);
        return;
      }

      console.log("Payment method created:", paymentMethod.id);

      const response = await fetch(`${API_URL}/stripe/subscribe`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: user._id,
          name,
          email: emaiil,
          product_id: productId,
          paymentMethod: paymentMethod.id,
        }),
      });

      if (!response.ok) {
        const responseText = await response.text();
        console.error("Failed to subscribe:", response.statusText, responseText);
        seterror("Subscription failed: " + responseText);
        setisLoading(false);
        return;
      }

      const subscriptionData = await response.json();
      console.log("Subscription request successful:", subscriptionData);

      const confirmResult = await stripe.confirmCardPayment(subscriptionData.clientSecret);
      console.log("Payment confirmation result:", confirmResult);

      if (confirmResult.error) {
        console.error("Error confirming card payment:", confirmResult.error);
        seterror("Payment confirmation failed: " + confirmResult.error.message);
        setStatus("fail");
      } else {
        setStatus("success");
        setIsPremiumMember(true);
        setisStripeSubscriptionEnabled(true);
        console.log("Payment successful, subscription active");
      }
    } catch (err) {
      console.error("Error in subscription process:", err);
      seterror("Payment failed! " + err.message);
      setStatus("fail");
    }
    setisLoading(false);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  return (
    <>
      {!status ? (
        <div className="stripe-container">
          <div className="productdropdown" onClick={() => setDropdown(!Dropdown)}>
            {data ? (
              <>
                {data.map((price) => {
                  if (price.id === productId) {
                    return (
                      <div key={price.id} className="product_content">
                        <h5 className="light-font">Subscribe to {price.product.name}</h5>
                        <h4>
                          {price.unit_amount / 100} {price.currency?.toUpperCase()} <span>/ {price.recurring.interval}</span>
                        </h4>

                        <p className="light-font">{price.product.description}</p>
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
            <div className="product_img">
              <img src={stockimg} alt=""></img>
            </div>
          </div>
          <div className="payment_right">
            <div className="light-font">Email</div>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                value={emaiil}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </div>
            <br />
            <div className="light-font"> Card Information</div>

            <div className="card_wrapper  ">
              <div className="cards_number">
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                <div className="cards-imgs">
                  <img src={cardsimg} alt=""></img>
                </div>
              </div>
              <div className="cvv_wrapper">
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                <div className="cvclogo">
                  <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                  <div className="cvc_card_logo">
                    <img src={cvclogo} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="light-font">Name on card</div>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter a name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </div>

            <br />
            <br />
            {error ? <div className="text-danger py-2 text-center">Please fill all the fields</div> : ""}
            {isLoading ? (
              <button className="pay_btn">Loading</button>
            ) : (
              <>
                {name === "" || emaiil === "" ? (
                  <button className="pay_btn opacity-50">Subscribe </button>
                ) : (
                  <button className="pay_btn " onClick={createSubscription}>
                    Subscribe
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="box">
            {status === "success" ? (
              <div className="success alert">
                <div className="alert-body">Success !</div>
                Payment Successful! Subscription active.
                <p>
                  Go to{" "}
                  <NavLink className="" to="/profile">
                    Profile
                  </NavLink>
                </p>
              </div>
            ) : (
              <div className="error alert">
                <div className="alert-body">Error !</div>
                {error}
                console.log(error)
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default PaymentForm;
