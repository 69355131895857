import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { API_URL } from "../config/config.index.js";

const AuthContext = createContext();

const AuthContextWrapper = (props) => {
  const [user, setUser] = useState(null);
  const [tokenState, setTokenState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPremiumMember, setIsPremiumMember] = useState(false); // New state for premium membership
  const [PremiumEnd, setPremiumEnd] = useState(false); // New state for premium membership
  const [isStripeSubscriptionEnabled, setisStripeSubscriptionEnabled] = useState(false); // New state for premium membership
  const [hasCreatedFreeCase, setHasCreatedFreeCase] = useState(false);

  const setToken = (token) => {
    localStorage.setItem("authToken", token);
    setTokenState(token);
  };

  const authenticateUser = async () => {
    const gotToken = localStorage.getItem("authToken");
    if (gotToken) {
      try {
        const { data } = await axios.get(`${API_URL}/auth/verify`, {
          headers: { authorization: `Bearer ${gotToken}` },
        });

        console.log("response from verify route", data);

        // If the server returns a message saying the session has expired,
        // log out the user by setting isLoggedIn to false and user to null
        if (data.message === "Session expired, please log in again.") {
          setIsLoggedIn(false);
          setUser(null);
          return;
        }

        setUser(data);
        setIsPremiumMember(data.isPremiumMember);
        setisStripeSubscriptionEnabled(data.isStripeSubscriptionEnabled);
        setHasCreatedFreeCase(data.hasCreatedFreeCase);
        if (data.subscription_enddate) {
          setPremiumEnd(
            new Date(data.subscription_enddate).getDate().toString().padStart(2, "0") +
              "/" +
              (new Date(data.subscription_enddate).getMonth() + 1).toString().padStart(2, "0") +
              "/" +
              new Date(data.subscription_enddate).getFullYear()
          );
        }
        setIsLoading(false);
        setIsLoggedIn(true);
      } catch (err) {
        console.log("there was an error on the authenticate user", err);
        setUser(null);
        setIsLoading(false);
        setIsLoggedIn(false);
      }
    } else {
      setUser(null);
      setIsLoading(false);
      setIsLoggedIn(false);
    }
  };

  const removeToken = () => {
    localStorage.removeItem("authToken");
  };

  const logOutUser = () => {
    // To log out the user, remove the token
    removeToken();
    authenticateUser();
  };

  // Function to check if the user is a premium member
  const checkPremiumMembership = () => {
    // Assuming your API has an endpoint to fetch user data after authentication
    //   axios
    //     .get(`${API_URL}/auth/user`, {
    //       headers: { authorization: `Bearer ${tokenState}` },
    //     })
    //     .then((response) => {
    //       const userData = response.data;
    //       setIsPremiumMember(userData.isPremiumMember);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching user data:", error);
    //     });
  };

  useEffect(() => {
    authenticateUser();
    checkPremiumMembership(); // Check premium membership on authentication
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setToken,
        authenticateUser,
        isLoading,
        isLoggedIn,
        setIsLoggedIn,
        user,
        logOutUser,
        isPremiumMember, // Provide isPremiumMember in the context value
        checkPremiumMembership,
        setIsPremiumMember, // Provide the checkPremiumMembership function in the context value
        isStripeSubscriptionEnabled,
        setisStripeSubscriptionEnabled,
        PremiumEnd,
        setPremiumEnd,
        hasCreatedFreeCase,
        setHasCreatedFreeCase,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextWrapper };
