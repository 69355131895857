import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../css/FullGMATExam.css";
import QuestionForExam from "./QuestionForExam";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import ScoreComponent from "./ScoreComponent";
import { AuthContext } from "../context/auth.context";
import axios from "axios";
import { API_URL_NEW_BACKEND } from "../config/config.index";

const FullGMATExam = () => {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useContext(AuthContext);

  const [currentSection, setCurrentSection] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [timeLeft, setTimeLeft] = useState(187 * 60);
  const [examStartTime, setExamStartTime] = useState(null);
  const [sections, setSections] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]); // Initialize as an array
  const [examStarted, setExamStarted] = useState(false);
  const [examFinished, setExamFinished] = useState(false);
  const [startingSection, setStartingSection] = useState("");

  const MAX_QUANT_QUESTIONS = 10; // Update as per the GMAT format (30)
  const MAX_VERBAL_QUESTIONS = 15; // Update as per the GMAT format (36)

  useEffect(() => {
    const fetchTest = async () => {
      try {
        const response = await axios.get(`${API_URL_NEW_BACKEND}/questiongeneration/test`);
        console.log("Backend Test:", response.data);
      } catch (error) {
        console.error("Error fetching test: ", error.message);
      }
    };
    fetchTest();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      toast.info("Please login to access this page");
      navigate("/login");
      return;
    }

    const fetchQuestions = async () => {
      try {
        console.log("Fetching questions..."); // Before Fetching Data
        const response = await axios.get(`${API_URL_NEW_BACKEND}/questiongeneration/questions-retrieval`);
        const questions = response.data;

        console.log("After fetching Questions:", questions);
        // Log the content.text of each question
        questions.forEach((question, index) => {
          console.log(`Question ${index + 1} content:`, question.content.text, "Type:", question.questionType, "ID:", question.questionId);
        });

        const processedSections = processQuestionsIntoSections(questions);
        console.log("Processed Sections:", processedSections); // After Processing Data
        setSections(processedSections);
        console.log("Sections:", sections); // After Setting Data
      } catch (error) {
        console.error("Error fetching questions: ", error.message);
        console.error("Stack trace: ", error.stack);
      }
    };

    fetchQuestions();
  }, [isLoggedIn, navigate]);

  const processQuestionsIntoSections = (questions) => {
    let sections = {
      "Quantitative Reasoning": [],
      "Verbal Reasoning": [],
      // Add other sections if needed
    };

    questions.forEach((question) => {
      const sectionKey = determineSection(question);
      if (sectionKey) {
        sections[sectionKey].push(question);
      }
    });

    return Object.entries(sections).map(([key, questions]) => {
      // Calculate total questions before they are grouped or shuffled
      const totalQuestionsInSection = questions.length;
      console.log(`Total questions in ${key}:`, totalQuestionsInSection);

      // Group questions by type
      const groupedQuestions = questions.reduce((groups, question) => {
        const group = groups[question.questionType] || [];
        group.push(question);
        groups[question.questionType] = group;
        return groups;
      }, {});

      // Shuffle and limit each group of questions
      const shuffledAndLimitedQuestions = Object.values(groupedQuestions).flatMap((group) => {
        return shuffleAndLimit(group, Math.ceil(determineMaxQuestions(key) / Object.keys(groupedQuestions).length));
      });

      return {
        key,
        displayName: key,
        questions: shuffledAndLimitedQuestions,
        totalQuestions: totalQuestionsInSection, // Adding total questions here
      };
    });
  };

  // Other helper functions like determineSection, determineMaxQuestions, shuffleAndLimit should exist in your codebase as they were implied in your previous code.

  const determineSection = (question) => {
    switch (question.questionType) {
      case "Data Sufficiency":
      case "Arithmetic":
      case "Algebra":
        return "Quantitative Reasoning";
      case "Critical Reasoning":
      case "Reading Comprehension":
      case "Sentence Correction":
        return "Verbal Reasoning";
      // Add other cases if needed
      default:
        return null;
    }
  };

  const determineMaxQuestions = (sectionKey) => {
    if (sectionKey === "Verbal Reasoning") return MAX_VERBAL_QUESTIONS;
    if (sectionKey === "Quantitative Reasoning") return MAX_QUANT_QUESTIONS;
    return 0;
  };

  const shuffleAndLimit = (questions, limit) => {
    return questions.sort(() => 0.5 - Math.random()).slice(0, limit);
  };

  const startExam = () => {
    setExamStartTime(new Date());
    setExamStarted(true);
  };

  const endQuestion = () => {
    const endTime = new Date();
    const timeTaken = endTime - examStartTime; // Time taken in milliseconds
    return timeTaken;
  };

  const submitAnswer = (questionId, selectedOption) => {
    setSelectedAnswer(selectedOption);
    const currentQuestion = sections[currentSection].questions[currentQuestionIndex];
    console.log(`Selected Answer for question ${currentQuestion.questionId}:`, selectedOption);
    console.log(`Current Question:`, currentQuestion);
    const isCorrect = currentQuestion.correctAnswer === selectedOption;

    // Log for debugging
    console.log("Current Question ID:", questionId);

    const attempt = {
      questionId: currentQuestion.questionId,
      questionType: currentQuestion.questionType,
      userAnswer: selectedOption,
      correctAnswer: currentQuestion.correctAnswer,
      isCorrect,
      timeTaken: endQuestion(),
      tags: currentQuestion.tags || [],
      difficulty: currentQuestion.difficulty || "unknown",
    };
    console.log(`Attempt object for question ${currentQuestion.questionId}:`, attempt);

    setAnswers((prevAnswers) => [...prevAnswers, attempt]);
  };

  const goToNextQuestion = () => {
    const currentQuestions = sections[currentSection]?.questions || [];
    if (currentQuestionIndex < currentQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer(null);
    } else if (currentSection < sections.length - 1) {
      setCurrentSection(currentSection + 1);
      setCurrentQuestionIndex(0);
      setSelectedAnswer(null);
    } else {
      endExam();
    }
  };

  const endExam = () => {
    setExamFinished(true);

    // Calculate total questions after processing the sections
    const totalQuestions = sections.reduce((acc, section) => acc + section.questions.length, 0);

    console.log("Total Questions:", totalQuestions); // Log the total questions for debugging

    // Construct a summary of attempts by question type
    // Construct a summary of attempts by question type
    const questionTypeSummaries = answers.reduce((summary, attempt) => {
      const { questionType, isCorrect } = attempt;

      // Debug: Log each attempt to see its structure
      console.log("Processing attempt: ", attempt);

      // Initialize the summary for this question type if it hasn't been already
      if (!summary[questionType]) {
        summary[questionType] = { total: 0, correct: 0 };
      }

      // Increment the count for total attempted questions of this type
      summary[questionType].total += 1;

      // If the question was answered correctly, also increment the count for correct answers
      if (isCorrect) {
        summary[questionType].correct += 1;
      }

      // Debug: Log the intermediate summary to see how it builds up
      console.log("Intermediate Summary:", summary);

      return summary; // Accumulate the results
    }, {});

    navigate("/gmat-exam-results", {
      state: {
        userAnswers: answers,
        userId: user.id,
        attempts: answers,
        totalQuestions, // Send this along
        questionTypeSummaries,
      },
    });
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="full-gmat-exam">
      <Helmet>
        <title>Full-Length GMAT Exam</title>
        <meta name="description" content="Start your full-length GMAT exam. Practice with real exam questions." />
        <meta name="keywords" content="GMAT, Exam, Full-Length, Practice" />
      </Helmet>

      {!examStarted ? (
        <div className="start-exam">
          <h1>Full-Length GMAT Exam</h1>
          <p>
            Welcome to your GMAT simulation exam. This full-length practice test is designed to closely mimic the structure and content of the actual
            GMAT. You will be presented with sections on Quantitative Reasoning, Verbal Reasoning, and Integrated Reasoning. Please ensure you are in
            a quiet environment and have allocated enough time to complete the exam without interruptions.
          </p>
          <p>
            Remember, this test will not only prepare you for the type of questions you'll encounter but also help you manage your time effectively.
            Good luck!
          </p>
          {!examStarted && (
            <div>
              <p>Choose the section you want to start with:</p>
              <div>
                <label>
                  <input
                    type="radio"
                    value="Verbal Reasoning"
                    checked={startingSection === "Verbal Reasoning"}
                    onChange={(e) => setStartingSection(e.target.value)}
                  />
                  Verbal Reasoning, Quantitative Reasoning
                </label>
                <label>
                  <input
                    type="radio"
                    value="Quantitative Reasoning"
                    checked={startingSection === "Quantitative Reasoning"}
                    onChange={(e) => setStartingSection(e.target.value)}
                  />
                  Quantitative Reasoning, Verbal Reasoning
                </label>
              </div>
            </div>
          )}
          <button onClick={startExam} disabled={startingSection === ""}>
            Start Exam
          </button>
        </div>
      ) : examFinished ? (
        // Render ScoreComponent when the exam is finished
        <ScoreComponent
          userAnswers={Object.values(answers)} // Make sure answers is not undefined before this point
          userId={user.id}
        />
      ) : (
        <div className="exam-section">
          <h2>{sections[currentSection]?.displayName}</h2>
          {sections[currentSection]?.questions.length > 0 && (
            <QuestionForExam
              question={sections[currentSection].questions[currentQuestionIndex]}
              onSubmitAnswer={submitAnswer}
              selectedAnswer={selectedAnswer}
              setSelectedAnswer={setSelectedAnswer}
            />
          )}
          <div className="navigation-buttons">
            <button
              className="next-btn"
              onClick={goToNextQuestion}
              disabled={examFinished || sections[currentSection]?.questions.length === 0 || !selectedAnswer}
            >
              {currentQuestionIndex < sections[currentSection]?.questions.length - 1 ? "Next Question" : "Next Section"}
            </button>
          </div>
          <div className="timer">{formatTime(timeLeft)}</div>
        </div>
      )}
    </div>
  );
};

export default FullGMATExam;
