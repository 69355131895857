import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "../css/HeroSection.css";
import quizImage from "../assets/Personal_Experience.png";

function HeroSection() {
  return (
    <section className="hero-section">
      <Helmet>
        <title>Master the GMAT with AI-Powered Precision</title>
        <meta
          name="description"
          content="Experience a revolutionary way to prepare for the GMAT. Tailored learning, real-time feedback, and adaptive tests to elevate your score."
        />
      </Helmet>
      <svg className="hero-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="none" stroke="#ffffff" strokeWidth="20" d="M0,320 C360,0 1080,320 1440,0" />
      </svg>

      <div className="hero-inner">
        <div className="hero-content">
          <h1 className="hero-title">Master the GMAT</h1>
          <p className="hero-subtitle">
            Excel at the GMAT with GMAT PRACTICE where endless practice meets smart, personalized guidance. Enjoy unlimited free questions, and when
            you need it, let our AI-powered platform provide tailored explanations and feedback to help you master even the toughest topics. It's your
            comprehensive tool to excel at the GMAT.
          </p>

          <div className="hero-buttons">
            <Link to="/choose" className="hero-button hero-button-primary">
              Begin Your Journey
            </Link>
            <Link to="/about" className="hero-button hero-button-secondary">
              Learn More
            </Link>
          </div>
        </div>
        <div className="picture-hero">
          <video autoPlay loop muted style={{ width: "100%" }} preload="auto">
            <source src="https://drive.google.com/uc?export=download&id=1lX_5KAKzwzoXNmpNAUy__YvOLs5LiUhC" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
