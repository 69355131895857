import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth.context";
import "../css/ProfilePage.css";
import { API_URL } from "../config/config.index";
import { toast } from "react-toastify";
import axios from "axios";

function ProfilePage() {
  const { isLoggedIn, user } = useContext(AuthContext);
  const [userPlan, setUserPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUserPlan() {
      if (!isLoggedIn || !user || !user.email) {
        toast.error("You must be logged in to view your plan");
        return;
      }

      try {
        setLoading(true);

        // Fetch Stripe Customer ID
        const customerIdResponse = await axios.get(`${API_URL}/stripe/get-stripe-customer-id/${user.email}`);
        const stripeCustomerId = customerIdResponse.data.stripeCustomerId;

        if (!stripeCustomerId) {
          console.warn("No Stripe customer ID found for user:", user.email);
          setUserPlan("No active subscription");
          return;
        }

        // Fetch Subscription Details
        const productResponse = await axios.get(`${API_URL}/stripe/user-subscription-details/${stripeCustomerId}`);
        const planName = productResponse.data.productName;
        setUserPlan(planName);
      } catch (error) {
        console.error("Error fetching user plan:", error);
        setUserPlan("Error fetching subscription details");
      } finally {
        setLoading(false);
      }
    }

    fetchUserPlan();
  }, [isLoggedIn, user]);

  return (
    <div className="profile-container">
      {isLoggedIn ? (
        <div className="profile-content">
          <h1>Profile Information</h1>
          <div className="divider"></div>
          <h3 className="section-title">Account</h3>
          <p className="details-text">User: {user.name}</p>
          <p className="details-text">Email: {user.email}</p>
          {loading ? (
            <p>Loading your subscription details...</p>
          ) : (
            <>
              <div className="divider"></div>
              <h3 className="section-title">Current Plan</h3>
              <p className="details-text">{userPlan || "You do not have an active subscription plan."}</p>
            </>
          )}
          <a href="/manage-subscription" className="button">
            Manage Subscription
          </a>
        </div>
      ) : (
        <p>Please log in to view your profile.</p>
      )}
    </div>
  );
}

export default ProfilePage;
