import React, { useState, useMemo, useContext } from "react";
import QuantitativeQuestion from "./QuantitativeQuestion";
import dataSufficiencyQuestions from "../components/pages/data/DataSufficiency.json";
import problemSolvingQuestions from "../components/pages/data/Algebra.json";
import integratedReasoningQuestions from "../components/pages/data/Arithmetic.json";
import "../css/Chatbot.css";
import { Helmet } from "react-helmet";
import PremiumChatInterface from "./PremiumChatInterface";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../config/config.index";
import { useNavigate } from "react-router-dom"; // Import to redirect user
import { AuthContext } from "../context/auth.context"; // Import your AuthContext here

const QuantitativeQuestionnaire = () => {
  const [questionType, setQuestionType] = useState("dataSufficiency"); // default question type
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPracticeMode, setIsPracticeMode] = useState(false);
  const [isPremium, setIsPremium] = useState(true); // Assuming premium status for testing
  const [resetChat, setResetChat] = useState(false);
  const [userPlan, setUserPlan] = useState("Basic Plan"); // Assuming basic plan for testing

  const { isLoggedIn, user } = useContext(AuthContext); // Use your AuthContext here
  const navigate = useNavigate(); // Initialize navigate hook

  useEffect(() => {
    async function fetchUserPlan() {
      // Check if user is logged in and has valid user data
      if (!isLoggedIn || !user || !user.email) {
        toast.info("Please log in to access this page.");
        navigate("/login"); // Redirect to login if not logged in or user data is not available
        return;
      }
    }

    fetchUserPlan();
  }, [isLoggedIn, user, navigate]);

  // Adjust to access the questions array inside each JSON object
  const questionsMap = {
    dataSufficiency: dataSufficiencyQuestions.questions,
    problemSolving: problemSolvingQuestions.questions,
    integratedReasoning: integratedReasoningQuestions.questions,
  };

  const shuffledQuestions = useMemo(() => {
    const questions = questionsMap[questionType];
    if (Array.isArray(questions)) {
      return [...questions].sort(() => Math.random() - 0.5);
    }
    return [];
  }, [questionType]);

  const currentQuestion = shuffledQuestions[currentQuestionIndex] || {};

  const handleNextQuestion = () => {
    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex((currentIndex) => currentIndex + 1);
      setResetChat(true);
    } else {
      alert("You've completed all questions in this category!");
      setCurrentQuestionIndex(0); // Optionally reset to the first question
    }
  };

  const togglePracticeMode = () => {
    setIsPracticeMode(!isPracticeMode);
  };

  const onResetDone = () => {
    setResetChat(false);
  };

  const handleQuestionTypeChange = (type) => {
    setQuestionType(type);
    setCurrentQuestionIndex(0);
  };

  const typeButtons = useMemo(() => {
    return Object.keys(questionsMap).map((type) => {
      const typeName = type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, " $1");
      const isActive = questionType === type;
      return (
        <button key={type} onClick={() => handleQuestionTypeChange(type)} className={`type-button ${isActive ? "active" : ""}`}>
          {typeName}
          {isActive && <div className="underline"></div>}
        </button>
      );
    });
  }, [questionType, questionsMap]);

  return (
    <div className="quantitative-questionnaire">
      <div className="type-buttons-container">{typeButtons}</div>

      <div className="practice-mode-toggle">
        <label htmlFor="practice-mode-switch" className="switch-label">
          Practice Mode:
        </label>
        <label className="practice-mode-switch">
          <input id="practice-mode-switch" type="checkbox" checked={isPracticeMode} onChange={togglePracticeMode} />
          <span className="slider round"></span>
        </label>
      </div>

      <QuantitativeQuestion
        questionData={currentQuestion}
        onNextQuestion={handleNextQuestion}
        isPracticeMode={isPracticeMode}
        isPremium={isPremium}
      />

      {isPremium && (
        <PremiumChatInterface
          userId={""}
          isPremiumUser={isPremium}
          currentQuestion={currentQuestion}
          resetChat={resetChat}
          onResetDone={onResetDone}
          subjectArea="math"
        />
      )}
    </div>
  );
};

export default QuantitativeQuestionnaire;
