/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
  width: 100vw;
  background-color: #102a43;
  color: #fff;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    justify-content: flex-start;
  }
`;

const headingStyle = css`
  background: linear-gradient(to right, #0093e9, #80d0c7);
  -webkit-background-clip: text;
  font-size: 2.5rem;
  color: transparent;
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`;

const highlightStyle = css`
  color: transparent;
  background: linear-gradient(to right, #0093e9, #80d0c7);
  -webkit-background-clip: text;
`;

const buttonStyle = css`
  background: linear-gradient(to right, #0093e9, #80d0c7);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 1rem;
  text-decoration: none;
  margin-top: 20px;
  text-align: center;
  width: 180px;
  display: inline-block;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 15px;
    font-size: 1.1rem;
    padding: 12px 20px;
  }
`;

// You can define and use buttonFocusStyle if you'd like some specific focus styles. For example:
const buttonFocusStyle = css`
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 147, 233, 0.5); // You can adjust this as you like
  }
`;

const videoStyle = css`
  width: 100%;
  max-width: 800px;
  height: 450px; // 16:9 Aspect Ratio
  border: 0;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    height: 300px; // Adjusting the video height for mobile devices
  }
`;

function TakeAction() {
  return (
    <div css={containerStyle}>
      <h2 css={headingStyle}>
        Ready to <span css={highlightStyle}>rock</span> your interview?
      </h2>
      <Link to="/choose" css={[buttonStyle, buttonFocusStyle]}>
        Start owning the case interview
      </Link>
      <iframe
        css={videoStyle}
        src="https://www.youtube.com/embed/sueoTv78-30" // Updated YouTube link
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default TakeAction;
